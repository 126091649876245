import "../assets/css/app_v1.css";
import AppAppBar from "../components/AppBar";
import MessageBar from '../features/messages/components/MessageBar';
import FeedBack from '../features/feedback/components/Feedback';

import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";
import {log} from "../utils"
export function AppLayout() {

    useEffect(() => {
        // add class to body element
        document.body.classList.add('app');
    }, []);

    try {
        return (
            <>
            <div className="bc-global-wrapper">
                <AppAppBar  />
                <MessageBar />
                <Outlet />
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <footer className="bc-page--footer">
                        <Container maxWidth="lg" className="text-muted" style={{ 
                            borderTop: "1px solid var(--bc-border-light-grey)",textAlign:'center', fontSize: ".875rem", marginTop: "-1px", paddingTop: "1rem", paddingBottom: "1rem" }}>
                     
                            <div>Version  {(process.env.REACT_APP_BUILD_ID) &&process.env.REACT_APP_BUILD_ID || "0.0.1"}</div>
                        </Container>
                    </footer>
                </Box>
              
                <Box sx={{ display: 'block', bottom: 0, left: 0, right: 0 }} elevation={3}>
                      {/* TODO: Change display xs to block when working. 
                    <AppBottomNavigation />
                    */}
                </Box>
                <FeedBack />
            </div>
            </>
        )

    } catch (error) {
        log("AppLayout error",error);
    }

    
}