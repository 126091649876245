import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import { req } from "../utils";

const PmJobNotes = ({ pm }) => {
    const [open, setOpen] = useState(false);
    const [notes, setNotes] = useState([]);
    const [noNotes, setNoNotes] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const getNotes = async () => {
        //get notes from the database
        //machine ids is an array of ints there could be one more more if there are one or more make them a csv if none use false
        let machines = pm.machine_ids.join(",");
        if (machines.length === 0) {
                machines = false;
        }
        let url = `get_pm_notes/?venue_id=${pm.venue_id}&machine_ids=${machines}`;
        let notes = await req("GET", url);
        if (notes.length === 0) {
            setNoNotes(true);
        }else{
            setNoNotes(false);
        }
        setNotes(notes);
    };
    useEffect(() => {
        if(open)
                getNotes();
    }, [open]);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button color="persiangreen" fullWidth variant="link" onClick={handleOpen}>
                Job Notes
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose}>
                <DialogTitle>
                    <Grid container spacing={1}>
                        <Grid item xs={10}>Job related notes</Grid>
                        <Grid item xs={2}>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon color="persiangreen" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {notes.length > 0 ? (
                        notes.map((note, index) => (
                            <div key={index} className="note-container">
                                <div className="note-bubble">
                                    <div className="note-subject">Note topic: {note.subject}</div>
                                    <div className="note-note">{note.note}</div>
                                    <div className="note-date">{new Date(note.created_time * 1000).toLocaleString()}</div>
                                    <div className="note-author">{note.account_name}</div>
                                </div>
                            </div>
                        ))
                    ) : (
                        noNotes ?
                            <p>No notes available</p>
                            :   
                        <p>Loading notes...</p>

                    )}
                </DialogContent>
                <Button variant="contained" color="persiangreen" onClick={handleClose}>
                    Close
                </Button>
            </Dialog>
            <style>
                {`
                    .note-container {
                        margin-bottom: 10px;
                    }
                    .note-bubble {
                        background-color: #f2f2f2;
                        padding: 10px;
                        border-radius: 5px;
                    }
                    .note-subject {
                        font-weight: bold;
                    }
                    .note-note {
                        margin-top: 5px;
                    }
                    .note-date {
                        font-size: 12px;
                        color: gray;
                    }
                    .note-author {
                        font-size: 12px;
                        color: gray;
                        margin-top: 5px;
                    }
                `}
            </style>
        </div>
    );
};

export default PmJobNotes;
