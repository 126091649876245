import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadImage, getExifDataAsync, resizeImage, storeFailedUpload } from '../utils'; // Adjust this import based on your utils.js implementation
import { Button, List, Box, ListItem, ListItemText, ListItemAvatar, Avatar, Paper } from '@mui/material';

const ImageUploader = ({ endpointUrl, primaryUse, primaryUseKey, otherFormData, successCallback, failureCallback }) => {
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadStatus, setUploadStatus] = useState({});
    const [upLoading, setUploading] = useState(false); // used to toggle button vs auto upload
    const autoUpload = true; // used to toggle button vs auto upload

    const onDropAccepted = useCallback((acceptedFiles) => {
        let data = [];
        // append to data and then setFiles
        for (let i = 0; i < acceptedFiles.length; i++) {
            data.push({
                file: acceptedFiles[i],
                preview: URL.createObjectURL(acceptedFiles[i]),
                metaData: {
                    primaryUse,
                    primaryUseKey,
                    ...(Array.isArray(otherFormData) ? Object.fromEntries(otherFormData.map(item => [item.name, item.value])) : {})
                },
                status: 'Waiting to upload...'
            });
        }
        setFiles(prevFiles => [...prevFiles, ...data]);
    }, [otherFormData, primaryUse, primaryUseKey]);

    useEffect(() => {
        if (autoUpload && files.length > 0) {
            handleUpload();
        }
    }, [files]);

    const updateStatus = (fileName, statusMessage) => {
        setUploadStatus(prevStatus => ({
            ...prevStatus,
            [fileName]: statusMessage
        }));
    };

    const handleUpload = async () => {
        if (upLoading) return;
        setUploading(true); // Singleton to prevent concurrent uploads

        for (const { file, metaData: originalMetaData } of files) {
            try {
                const exifData = await getExifDataAsync(file);
                //filter out MakerNote
                try{
                delete exifData.MakerNote;
                }catch(e){
                    console.log("no MakerNote to delete");
                }
                // Filter out the 'undefined' property or any other unwanted data
                const { undefined, ...filteredExifData } = exifData;

                // Ensure metaData is an object; if not, initialize as empty object
                const metaData = typeof originalMetaData === 'object' && originalMetaData !== null ? originalMetaData : {};

                // Combine filtered EXIF data with any existing metaData under a unique key
                const combinedMetaData = {
                    ...metaData,
                    [`image_${file.name.replace(/\s+/g, '_')}`]: filteredExifData,
                };

                const resizedImage = await resizeImage(file, 1600, 600);
                const progressCallback = progress => {
                    console.log(`PROGRESS ${file.name}: ${progress}%`);
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [file.name]: progress,
                    }));
                };

                const response = await uploadImage(endpointUrl, resizedImage, combinedMetaData, progressCallback);
                if (!response) {
                    // Mark upload as failed but will try again
                    storeFailedUpload(endpointUrl, resizedImage, combinedMetaData);
                    updateStatus(resizedImage.fileName, 'Upload failed, but we will retry shortly...');
                    failureCallback(response);
                } else {
                    //console.log(`Uploaded ${file.name}`, response);
                    // Remove from list and add images to files
                    setFiles(prevFiles => prevFiles.filter(item => item.file.name !== file.name));
                    successCallback(response);
                }
            } catch (e) {
                console.error(e);
                console.log('Error occurred during upload:', e.message);
            }
        }
        setUploading(false);
    };

    const removeFile = (index) => {
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const { getRootProps, getInputProps } = useDropzone({ onDropAccepted, accept: 'image/*' });

    return (
        <>
            {!upLoading &&
                <Box key={"uploader"}  {...getRootProps()} style={dropzoneStyle}>
                    <input {...getInputProps()} />
                    <p>Drop files here</p>
                </Box> || <Box key={"loader"} style={dropzoneStyle}>Uploading...</Box>}
            <List style={{ width: '100%', maxWidth: '100%' }}>
                {files.map((file, index) => (
                    <Paper key={file.file.name} elevation={1} sx={{ padding: '.3rem', marginBottom: '.5rem' }}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <img alt={`Preview of ${file.file.name}`} src={file.preview} style={{ width: '100%', height: 'auto' }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={file.file.name}
                                secondary={uploadStatus[file.file.name] || file.status}
                            />
                        </ListItem>
                    </Paper>
                ))}
            </List>
            {autoUpload ? (<div className='text-center'>Images will automatically upload</div>) : <Button onClick={handleUpload} fullWidth color='persiangreen' variant='contained' disabled={files.length === 0}>Upload</Button>}
        </>
    );
};

const dropzoneStyle = {
    border: '2px dashed #c4c4c4',
    borderRadius: '12px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#c4c4c4',
    marginBottom: '20px',
    transition: 'border .24s ease-in-out',
    width: '100%',
    maxWidth: '100%',
};

export default ImageUploader;