import moment from 'moment';
import { useDataContext } from '../../contexts/DataContext';
import { useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Close, History as HistoryIcon } from '@mui/icons-material';
import { req, log } from '../../utils';

export function MachineHistory({ machineID, machineSerialID }) {
    const { machines, setMachines, venueLocations } = useDataContext();
    const [machineHistory, setMachineHistory] = useState([]);
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');

    const formatTime = (time) => {
        let localFormattedTime = moment.unix(time).format('DD/MM/YYYY HH:mm');
        return (
            <>
                <span style={{ whiteSpace: "nowrap" }}>{localFormattedTime} </span>
            </>
        );
    }

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
        getMachineHistory();
    };

    const handleClose = () => {
        setOpen(false);
    };
    const getSerialID = (machine_id) => {

        let serial = machines.filter(x => String(x.machine_id_pk) === String(machine_id));
        if (serial.length > 0) {
            return serial[0].serial_id;
        }
        return "No serial";
    }
    const getVenueName = (venue_id) => {
        let venue = "No venue";
       
        if (venueLocations) {
            let venue = venueLocations.filter(x => String(x.venue_id_fk) === String(venue_id));
            if (venue.length > 0) {
                return venue[0].venue_name;
            }
        }
        return venue;
    }

    const getLocationName = (location_id) => {
        let location = "No location";
        let locations = localStorage.getItem("_bc_data_venue_locations");
        if (locations) {
            locations = JSON.parse(locations);
            let location = locations.filter(x => String(x.location_id) === String(location_id));
            if (location.length > 0) {
                return location[0].location;
            }
        }
        return location;
    }

    const format_history = (category, history) => {
        if (category.includes("machine_move_")) {
            let from_venue = "No venue";
            let from_location = "No location";
            let to_location = "No location";
            if (history.from_venue_id) {
                from_venue = getVenueName(history.from_venue_id);
            }
            if (history.from_location_id)
                from_location = getLocationName(history.from_location_id);
            let to_venue = "No venue";
            if (history.to_venue_id)
                to_venue = getVenueName(history.to_venue_id);
            if (history.to_location_id)
                to_location = getLocationName(history.to_venue_location_id);
            return (
                <>
                    <span style={{ whiteSpace: "nowrap" }}>
                        <div>Moved from: {from_venue}</div>
                        <div>Location: {from_location}</div>
                        <div>To: {to_venue}</div>
                        <div>Location: {to_location}</div>
                    </span>
                </>
            );
        }
        if (category === "install") {
            try {
                const details = JSON.parse(history);
                const innerDetails = JSON.parse(details.details);
                console.log("innerDetails", innerDetails);
                return (
                    <span style={{ whiteSpace: "nowrap" }}>
                        <div>From Venue: {getVenueName(details.from_venue)}</div>
                        <div>From Location: {getLocationName(details.from_location)}</div>
                        <div>To Venue: {getVenueName(details.to_venue)}</div>
                        <div>To Location: {getLocationName(details.to_location)}</div>
                        <div>Installed Machine ID: {getSerialID(innerDetails.installed_machine_id)}</div>
                        <div>service call id: {innerDetails.service_call_id}</div>
                    </span>
                );
            } catch (error) {
                console.error("Error parsing install history:", error);
                return "Error parsing install details";
            }
        }
        if (category === "swap") {
            try {
                const details = JSON.parse(history);
                return (
                    <span style={{ whiteSpace: "nowrap" }}>
                        <div>From Venue: {getVenueName(details.from_venue_id)}</div>
                        <div>To Warehouse: {getVenueName(details.to_warehouse_id)}</div>
                        <div>To Warehouse Location: {getLocationName(details.to_warehouse_location_id)}</div>
                        <div>Machine to Venue: {getSerialID(details.machine_to_venue)}</div>
                        <div>Machine to Warehouse: {getSerialID(details.machine_to_warehouse)}</div>
                        <div>Service Call ID: {details.service_call_id}</div>
                        <div>Time: {moment(details.time).format('DD/MM/YYYY HH:mm:ss')}</div>
                    </span>
                );
            } catch (error) {
                console.error("Error parsing swap history:", error);
                return "Error parsing swap details";
            }
        }
        return JSON.stringify(history);
    }

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const getMachineHistory = () => {
        if (navigator.onLine) {
            req('get', 'get_machine_history', { machine_id: machineID })
                .then((response) => {
                    setMachineHistory(response);
                })
                .catch((error) => {
                    log("Machine History API Error", error);
                });
        } else {
            const filteredMachine = machines.filter(machine => String(machine.machine_id_pk) === String(machineID));
            setMachineHistory(filteredMachine[0]?.history || []);
        }
    };

    return (
        <>
            <div className="machine-details--wrapper" style={{maxWidth: "700px"}}>
                <span className="machine-details--title text-muted">Machine History:</span>
                <span className="machine-details--text" style={{ cursor: "pointer" }} onClick={handleClickOpen()}>
                    View History
                    <IconButton alt="View History" aria-label="view history" size="small" sx={{ marginLeft: '.25rem', padding: '3px' }}>
                        <HistoryIcon
                            color="persiangreen"
                            fontSize="medium"
                        />
                    </IconButton>
                </span>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth="xl"
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle>
                    <div>Machine History for Serial ID: {machineSerialID}</div>
                    <IconButton alt="close" aria-label="close" onClick={handleClose} size="small">
                        <Close fontSize="small" />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    {machineHistory.length > 0 ? (
                        <TableContainer component={Paper} style={{ width: '100%' }}>
                            <Table stickyHeader style={{ overflowX: 'hidden' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: "700" }}>Date / Account</TableCell>
                                        <TableCell style={{ fontWeight: "700" }}>Category</TableCell>
                                        <TableCell style={{ fontWeight: "700" }}>Details</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {machineHistory.sort((a, b) => b.time - a.time).map((row) => (
                                        <TableRow
                                            key={row.history_id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>
                                                <div>{formatTime(row.time)}</div>
                                                <div>{row.account_name}</div>
                                            </TableCell>
                                            <TableCell>{row.history_category.replace(/_/g, " ")}</TableCell>
                                            <TableCell style={{ whiteSpace: "wrap" }}>{format_history(row.history_category, row.information)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <div style={{ padding: "2rem 0", textAlign: "center" }}>No Machine History found for this machine.</div>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}