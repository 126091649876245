import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MachineRow } from "./MachineRow";
import { useDataContext } from "../../contexts/DataContext";

export function JobDetails({ job }) {
  const { machines } = useDataContext();
  const { machine_ids_array, log, service_venue_id } = job;
  const [swappedMachines, setSwappedMachines] = useState([]);
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [machinesAtVenue, setMachinesAtVenue] = useState([]);
  const [categorizedMachines, setCategorizedMachines] = useState({
    installed: [],
    removed: [],
    current: [],
    swapped: []
  }); 

  useEffect(() => {
    let filtered = [];
    if (
      machine_ids_array &&
      Array.isArray(machine_ids_array) &&
      machine_ids_array.length
    ) {
      filtered = machine_ids_array
        .map(id => machines.find(machine => String(machine.machine_id_pk) === String(id)))
        .filter(Boolean);
      setFilteredMachines(filtered);
    } else {
      setFilteredMachines([]);
    }
  }, [machine_ids_array, machines, log]);

  useEffect(() => {
    try {
      if (machines && Array.isArray(machines) && machines.length && service_venue_id && machine_ids_array) {
        const filteredAtVenue = machines.filter(machine => 
          String(machine.venue_id) === String(service_venue_id) &&
          !machine_ids_array.includes(String(machine.machine_id_pk))
        );
        setMachinesAtVenue(filteredAtVenue);
      } else {
        setMachinesAtVenue([]);
      }
    } catch (error) {
      console.error("Error filtering machines at venue:", error);
      setMachinesAtVenue([]);
    }
  }, [machines, service_venue_id, machine_ids_array]);

  const machineMovements = useMemo(() => {
    if (log && Array.isArray(log)) {
      return log.filter(
        (logItem) =>
          logItem.event === "machine_installed" ||
          logItem.event === "machine_removed" ||
          logItem.event === "machine_swapped"
      );
    }
    return [];
  }, [log]);

  useEffect(() => {
    if (log && Array.isArray(log) && machines && Array.isArray(machines)) {
      const installed = [];
      const removed = [];
      const current = [...filteredMachines];
      const swapped = [];

      const processedMachines = new Set();

      machineMovements.forEach((logItem) => {
        if (logItem.event === 'machine_installed' || (logItem.event === 'machine_moved' && logItem.details.installed_machine_id)) {
          const machineId = logItem.details.installed_machine_id || logItem.details.machine_id;
          const machine = machines.find(m => String(m.machine_id_pk) === String(machineId));
          if (machine) {
            installed.push({ ...machine, installedAt: logItem.time });
            // Remove from current if it exists
            const index = current.findIndex(m => m.machine_id_pk === machine.machine_id_pk);
            if (index !== -1) current.splice(index, 1);
          }
          processedMachines.add(machineId);
        } else if (logItem.event === 'machine_removed' || (logItem.event === 'machine_moved' && logItem.details.machine_id)) {
          const machineId = logItem.details.removed_machine_id || logItem.details.machine_id;
          const machine = machines.find(m => String(m.machine_id_pk) === String(machineId));
          if (machine) {
            removed.push({ ...machine, removedAt: logItem.time });
            // Remove from current if it exists
            const index = current.findIndex(m => m.machine_id_pk === machine.machine_id_pk);
            if (index !== -1) current.splice(index, 1);
          }
          processedMachines.add(machineId);
        } else if (logItem.event === 'machine_swapped') {
          if (!logItem.details.machine_to_venue || !logItem.details.machine_to_warehouse) {
            console.error("Invalid swap log item structure:", logItem);
            return; // Skip invalid log items
          }

          const inMachine = machines.find(m => String(m.machine_id_pk) === String(logItem.details.machine_to_venue));
          const outMachine = machines.find(m => String(m.machine_id_pk) === String(logItem.details.machine_to_warehouse));
          
          if (inMachine) {
            swapped.push({ ...inMachine, swappedIn: true, swappedAt: logItem.time });
            current.push(inMachine); // Add swapped-in machine to current
          }
          if (outMachine) {
            swapped.push({ ...outMachine, swappedIn: false, swappedAt: logItem.time });
            const index = current.findIndex(m => m.machine_id_pk === outMachine.machine_id_pk);
            if (index !== -1) current.splice(index, 1); // Remove swapped-out machine from current
          }
          processedMachines.add(logItem.details.machine_to_venue);
          processedMachines.add(logItem.details.machine_to_warehouse);
        }
      });

      // Filter out processed machines from the current list
      const updatedCurrent = current.filter(machine => !processedMachines.has(String(machine.machine_id_pk)));

      setCategorizedMachines({ installed, removed, current: updatedCurrent, swapped });
    }
  }, [machineMovements, machines, filteredMachines]);

  return (
    <>
      {categorizedMachines.installed.length > 0 && (
        <Box
          component="fieldset"
          sx={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "16px",
            marginTop: "20px",
          }}
        >
          <Typography component="legend" variant="h6" gutterBottom>
            Installed Machines
          </Typography>
          {categorizedMachines.installed.map((machine) => (
            <MachineRow
              key={machine.machine_id_pk}
              job={job}
              machine={machine}
              hasMoved={true}
              movementType="installed"
            />
          ))}
        </Box>
      )}

      {categorizedMachines.removed.length > 0 && (
        <Box
          component="fieldset"
          sx={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "16px",
            marginTop: "20px",
          }}
        >
          <Typography component="legend" variant="h6" gutterBottom>
            Removed Machines
          </Typography>
          {categorizedMachines.removed.map((machine) => (
            <MachineRow
              key={machine.machine_id_pk}
              machine={machine}
              hasMoved={true}
              movementType="removed"
            />
          ))}
        </Box>
      )}

      {categorizedMachines.current.length > 0 && (
        <Box
          component="fieldset"
          sx={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "16px",
            marginTop: "20px",
          }}
        >
          <Typography component="legend" variant="h6" gutterBottom>
            Machines in this call
          </Typography>
          {categorizedMachines.current.map((machine) => (
            <MachineRow
              key={machine.machine_id_pk}
              job={job}
              machine={machine}
              hasMoved={false}
            />
          ))}
        </Box>
      )}

      {categorizedMachines.swapped.length > 0 && (
        <Box
          component="fieldset"
          sx={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "16px",
            marginTop: "20px",
          }}
        >
          <Typography component="legend" variant="h6" gutterBottom>
            Swapped Machines
          </Typography>
          {categorizedMachines.swapped.map((machine) => (
            <MachineRow
              key={machine.machine_id_pk}
              job={job}
              machine={machine}
              hasMoved={true}
              movementType={machine.swappedIn ? "Swapped In" : "Swapped Out"}
            />
          ))}
        </Box>
      )}

      {machinesAtVenue.length > 0 && (
        <Accordion
          sx={{
            marginTop: "20px",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="other-machines-content"
            id="other-machines-header"
          >
            <Typography variant="h6">
              Other Machines at This Venue ({machinesAtVenue.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "16px",
              }}
            >
              {machinesAtVenue.map((machine) => (
                <MachineRow
                  key={machine.machine_id_pk}
                  job={job}
                  machine={machine}
                  hasMoved={false}
                />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}
