import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { log } from './utils';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

// Global error handler for runtime errors
window.onerror = function (message, source, lineno, colno, error) {
  const errorData = {
    type: 'runtime error',
    message: message,
    stack: error.stack,
    source: source,
    line: lineno,
    column: colno,
    url: window.location.href,
  };
  log('js_error window.onerror', errorData); // Send error data to your logging service
  return false; // Return false to prevent the default browser handler
};

// Global handler for unhandled promise rejections
window.onunhandledrejection = function (event) {
  const errorData = {
    type: 'unhandled promise rejection',
    message: event.reason.message || 'No error message available',
    stack: event.reason.stack || 'No stack trace available',
    url: window.location.href,
  };
  log('onunhandledrejection', errorData); // Send error data to your logging service
};

try {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <CssBaseline />
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
} catch (error) {
  log('render error', error); // Log the render error
}
