import React, { useEffect, useState } from "react";
import { Grid, Button, Chip, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Lightbox from "yet-another-react-lightbox";
import { Zoom } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { req, log } from "../../utils";
import { useDataContext } from "../../contexts/DataContext";

function useParsedImages(images, auth) {
  const [parsedImages, setParsedImages] = useState([]);

    useEffect(() => {
        const imageArray = Array.isArray(images) ? images : [];
        const newImages = imageArray.map(image => {
            let src = '';
            if (typeof image === 'string') {
                src = `https://app.bottlecycler.com/api/technicians/get_call_image/?img=${encodeURIComponent(image.replace(/^\/+/g, ''))}&a=${auth}`;
            } else if (image?.path && image.path.length > 0) {
                src = `https://app.bottlecycler.com/api/technicians/get_call_image/?img=${encodeURIComponent(image.path.replace(/^\/+/g, ''))}&a=${auth}`;
            }
            return src ? { src, width: 1, height: 1, notes: image.notes || '', image, tags: image?.tags || [], image_id: image.id } : null;
        }).filter(image => image !== null);
        setParsedImages(newImages);
    }, [images, auth]);
    try {
        // Code that may throw an error
    } catch (error) {
        log('Error occurred', error);
    }
    return parsedImages;
}
export default function ProtectedImageGallery({
  images,
  maxWidth,
  maxHeight,
  collectionName,
}) {
  const { service_call_id } = useParams();
  const { jobs, updateProperty } = useDataContext();
  const auth = localStorage.getItem("_bc_tech_token");
  const filteredPhotos = useParsedImages(images, auth);
  const [galleryImages, setGalleryImages] = useState(filteredPhotos);
  const [index, setIndex] = useState(-1);
  const [selectedTag, setSelectedTag] = useState(null); // Track the selected tag

  useEffect(() => {
    if (selectedTag) {
      const filteredByTag = filteredPhotos.filter((photo) =>
        photo.tags.some((tag) => tag?.title === selectedTag)
      );
      setGalleryImages(filteredByTag);
    } else {
      setGalleryImages(filteredPhotos);
    }
  }, [filteredPhotos, selectedTag]); // Update when filteredPhotos or selectedTag changes

  const handleDeleteImage = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (confirmDelete) {
      req("POST", `delete_image_v2`, { id });
      //remove from context by removing by image id
      let newImages = [...images];
      newImages = newImages.filter(
        (image) => String(image.image_id) !== String(id)
      );
      updateProperty("jobs", service_call_id, "call_images", newImages);
      setGalleryImages(
        galleryImages.filter((image) => String(image.image_id) !== String(id))
      );
    }
  };

  if (galleryImages?.length === 0) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <div>No images available.</div>
      </Grid>
    );
  }
  const filterTags = (tagTitle) => {
    setSelectedTag(tagTitle); // Update the selected tag to filter by
  };

  const formatTags = (tags) => {
    if (!tags || tags?.length === 0) return null;
    //fitler out non objects
    tags = tags.filter((tag) => typeof tag === "object");
    return tags.map((tag) => (
      <Chip
        key={tag.title}
        onClick={() => filterTags(tag.title)}
        label={tag.title}
        size="small"
        sx={{ margin: ".2rem" }}
        color="persiangreen"
        variant="outlined"
      />
    ));
  };

  //console.log('galleryImages:', galleryImages);

  return (
    <>
      <Grid container spacing={2}>
        {galleryImages.map((photo, photoIndex) => (
          <Grid item key={photoIndex} xs={12} sm={6} md={4}>
            <Paper
              elevation={1}
              sx={{ padding: ".3rem", position: "relative" }}
            >
              <div className="gallery-image-area">
                <img
                  src={photo.src}
                  alt={`Image ${photoIndex}`}
                  className="gallery-image"
                  onClick={() => setIndex(photoIndex)}
                  // Styling is already done by the class, inline style is not necessary
                />
                <div className="gallery-image-tags">
                  {formatTags(photo?.tags)}
                </div>
                <Button
                  className="gallery-delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteImage(photo.image_id);
                  }}
                  sx={{
                    position: "absolute",
                    top: "0.5em",
                    right: "0.5em",
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.6)",
                    "&:hover": {
                      backgroundColor: "rgba(0,0,0,0.8)",
                    },
                    borderRadius: "4px",
                    padding: "0.2em",
                  }}
                >
                  <DeleteIcon />
                </Button>
                {photo.notes && (
                  <div className="gallery-image-notes">{photo.notes}</div>
                )}
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {index >= 0 && (
        <Lightbox
          index={index}
          slides={galleryImages.map((photo) => ({
            src: photo.src,
            alt: photo.notes,
            title: photo.notes,
          }))}
          open={index >= 0}
          close={() => setIndex(-1)}
          plugins={[Zoom]}
        />
      )}
    </>
  );
}
