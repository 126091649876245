import React, { useState, useEffect } from "react";
import moment from "moment";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";

export const WeekDayButtons = () => {
  const { date } = useParams(); // This will get the date from the URL
  const selectedWeek = date ? moment(date) : moment(); // Use the URL date or the current date
  const weekDays = getWeekDays(selectedWeek); // Calculate the week days based on the selected week
  const [selectedDate, setSelectedDate] = useState(
    selectedWeek.format("YYYYMMDD")
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Determine the week based on the URL date, if available, or use the current week
  const urlDateMoment = date ? moment(date, "YYYY-MM-DD") : moment();

  useEffect(() => {
    // Scroll to the selected date's section
    const selectedElement = document.getElementById(selectedDate);
    if (selectedElement) {
      selectedElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [selectedDate]);
  const handleScroll = () => {
    const navbarHeight = document.getElementById("days-buttons").offsetHeight;
    const offset = navbarHeight -10; // adjust the offset as needed
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    const selectedElement = document.getElementById(selectedDate);
    if (selectedElement) {
      const elementPosition = selectedElement.getBoundingClientRect().top + scrollPosition;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // Scroll to the selected date's section
    handleScroll();
  }, [selectedDate]);

  const handleClick = (date) => {
    setSelectedDate(date);
  };

  return (
    <Stack
      direction={"row"}
      spacing={1}
      justifyContent="center"
    >
      {weekDays.map((date) => (
        <Button
          size="small"
          key={date.format("YYYYMMDD")}
          onClick={() => handleClick(date.format("YYYYMMDD"))}
          className="day-button"
          variant={
            selectedDate === date.format("YYYYMMDD") ? "contained" : "outlined"
          }
          color="persiangreen" 
          sx={{minWidth: isMobile ? "auto" : "50px"}}
        >
          <div className="">{date.format("ddd")}</div>
          <div>{date.format("D")}</div>
          <div className='day-jobs' id={`jobs-date-${date.format("YYYYMMDD")}`}></div>
        </Button>
      ))}
    </Stack>
  );
};

// This function now takes the selected week as an argument
function getWeekDays(dateMoment) {
    // Change 'isoWeek' to 'week' if the week should start on Sunday
    let startOfWeek = dateMoment.clone().startOf('week'); 
    return Array.from({ length: 7 }).map((_, index) => startOfWeek.clone().add(index, 'days'));
}

export default WeekDayButtons;
