import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, CircularProgress, Grid } from '@mui/material';
import { log, req } from "../../utils";
import { useDataContext } from '../../contexts/DataContext';
import { CompleteJob } from './CompleteJob';
export function JobProgress({ jobStatus, job }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const { service_call_id } = useParams();
    const { updateProperty } = useDataContext();
    const { jobs } = useDataContext();


    const handleOnClick = async (value) => {
        setIsSubmitting(true);
        const currentUnixTimestamp = Math.floor(new Date().getTime() / 1000);
        //console.log("currentUnixTimestamp:", value, currentUnixTimestamp);
        if (value === 'completed') {
            return;
        }

        const postData = {
            service_call_id: service_call_id,
            status: value,
            log_time: currentUnixTimestamp,
        };

        try {
            req("POST", "change_status", postData, true);
            //set local context
            updateProperty('job', service_call_id, 'status', value);
            setIsSubmitting(false);

        } catch (error) {
            log("Error onUpdateJobStatus:", error);
        } finally {
            setIsSubmitting(false);
        }
    };
    //if we are going to show the complete button then see if the job types incldue this job type

    const renderButton = (text, value) => {
        const showCompleteButtonTypes = ['BREAKDOWN', 'MACHINE REPLACEMENT', 'MACHINE RELOCATION', 'MAINTENANCE REQUEST', 'NOISE COMPLAINT', 'PREVENTATIVE MAINTENANCE','PREVENTIVE MAINTENANCE'];
        //if job is in array regardless of case
        //console.log("job?.service_call_type?.toUpperCase():", job?.service_call_type?.toUpperCase(), showCompleteButtonTypes.includes(job?.service_call_type?.toUpperCase()));
        const showCompleteButton = showCompleteButtonTypes.includes(job?.service_call_type?.toUpperCase());
        if (value === 'COMPLETED' && showCompleteButton) {
            return <CompleteJob job={job} />
        }
        return (
            <Button
                variant="contained"
                className={isSubmitting ? 'btn-submit btn-persiangreen-hover' : 'btn-submit'}
                color="persiangreen"
                sx={{ width: '100%' }}
                disabled={isSubmitting}
                onClick={() => handleOnClick(value)}
            >
                {isSubmitting ? `${text}ing` : text}
                {isSubmitting && <CircularProgress size={16} style={{ marginLeft: '.5rem', marginTop: "-4px" }} />}
            </Button>)
    };

    return (
        <div className='pt-1 pb-2 text-center'>
            {jobStatus === 'not started' ? (
                renderButton('Start Job', 'in progress')
            ) : jobStatus === 'in progress' ? ( // Added colon here
                renderButton('Complete Job', 'COMPLETED')
            ) : jobStatus === 'completed' ? (
                <h3>Job Completed.</h3>
            ) : jobStatus === 'cancelled' ? (
                <h3>Job Cancelled.</h3>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={6}> {renderButton('Start Job', 'IN PROGRESS')}</Grid>
                    <Grid item xs={6}>
                        {renderButton('Complete Job', 'COMPLETED')}
                    </Grid>

                </Grid>
            )}

        </div>
    );
}