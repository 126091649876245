import { Container } from "@mui/material";
import { Button } from "@mui/material";
import { WarningAmberRounded } from '@mui/icons-material';

export default function SyncDataBar() {

    const handleSyncData = () => {
        console.log("Sync Data");
    }

    return (<>
        <div className="syncData-bar">
            <Container maxWidth="lg">
                <div className="message-bar__content">
                    <div className="message-bar__icon">
                        <WarningAmberRounded style={{ fontSize: "2rem", lineHeight: "2rem" }} />
                    </div>
                    <div className="message-bar__text">
                        Data is not current. Please&nbsp;sync data now.
                    </div>
                    <div className="message-bar__actions">
                        <Button variant="outlined" size="small" className="btn-outline-white" onClick={handleSyncData}>Sync&nbsp;Data</Button>
                    </div>
                </div>
            </Container>
        </div>
    </>);
}