import React, { useState } from 'react';
import { Card, CardContent, Grid, Button, CardActions, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';


export const Note = ({ note,handleDeleteNote,sortTags,getVenueName,getMachineSerial }) => {
    const [tags, setTags] = useState(note.tags); // Initialize tags from note prop

    const formatdate = (unixTime) => {
        const d = new Date(unixTime * 1000);
        return d.toLocaleString();
    }
    const formatTags = (tag) => {
        let tagName = tag.foreign_subject === 'machine'
            ? `Machine ${getMachineSerial(tag.foreign_id)}` 
            : `Venue ${getVenueName(tag.foreign_id)}`;

        return (
            <Chip
                key={`${tag.foreign_subject}-${tag.foreign_id}`}
                size='small'
                sx={{ margin: '.5rem' }}
                color="persiangreen"
                variant="outlined"
                label={tagName}
                onClick={sortTags(tag)}
    
            />
        );
    };
    const formatSubject = (subject) => {
        return <span className='note-subject'>{subject.replace(/_/g, ' ')}</span>;
    }
    const formatNotes = (note) => {
        //adding in line breaks for notes
        return note.split('\n').map((item, key) => {
            return <span key={key}>{item}<br /></span>
        });
    }
    // Render the note with its tags and a delete button for the note itself
    return (
        <Card key={note.note_id} sx={{ mb: 2 }}>
            <CardContent>
                <Grid container>
                    <Grid className='note-meta' item xs={6}>From: {formatSubject(note.subject)}</Grid>
                    <Grid className='note-meta' item xs={6} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        By: {note.created_by} on {formatdate(note.created_time)}
                    </Grid>
                    <Grid item xs={12} sx={{ borderTop: '1px solid rgba(0,0,0,.1)', borderBottom: '1px solid rgba(0,0,0,.1)', paddingTop: '1rem', paddingBottom: '1rem' }}>{formatNotes(note.note)}</Grid>
                    <Grid item xs={8}>
                        {tags?.map(formatTags)}
                    </Grid>
                    <Grid className='note-meta' item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <CardActions>
                            {/*<Button size="small" color="persiangreen" onClick={() => handleEditNote(note.note_id)}><EditIcon /></Button>*/}
                            <Button size="small" color="persiangreen" onClick={() => handleDeleteNote(note.note_id)}><DeleteIcon /></Button>
                        </CardActions>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Note;
