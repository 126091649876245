import React, { useState, useEffect } from "react";
import { Select, Menu, MenuItem, IconButton, FormControl,InputLabel } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckIcon from "@mui/icons-material/Check";
import { useDataContext } from "../../contexts/DataContext";
import { useLocation } from "react-router-dom";
const JobsFilter = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [navTech, setNavTech] = useState(0);
  const { scheduleFilter, setScheduleFilter, isAdmin, technicians,setTechFilter } =
    useDataContext();
  const [showFilter, setShowFilter] = useState(false);
  const location = useLocation();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (value) => {
    setScheduleFilter(value);
    handleCloseMenu();
  };
  const handleTechFilter = (event) => {
    console.log("tech filter",event.target.value);
    handleCloseMenu();
    setTechFilter(event.target.value);
    setNavTech(event.target.value);
  }
  useEffect(() => {
    setShowFilter(location.pathname.includes("schedule"));
  }, [location.pathname]); // Add location.pathname as a dependency
  if (!showFilter) return null;
return (
    <>
        <IconButton
            aria-controls="filter-menu"
            aria-haspopup="true"
            onClick={handleOpenMenu}
            color="inherit"
        >
            <FilterListIcon />
        </IconButton>
        <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
        >
            <MenuItem onClick={() => handleFilterChange("ALL")}>
                {scheduleFilter === "ALL" && <CheckIcon />} All Jobs
            </MenuItem>
            <MenuItem onClick={() => handleFilterChange("COMPLETED")}>
                {scheduleFilter === "COMPLETED" && <CheckIcon />} Completed Jobs
            </MenuItem>
            <MenuItem onClick={() => handleFilterChange("NOT COMPLETED")}>
                {scheduleFilter === "NOT COMPLETED" && <CheckIcon />} Not Completed
                Jobs
            </MenuItem>
            {isAdmin && (
                <div style={{"padding":"1rem"}}>
                <FormControl fullWidth>
                    <InputLabel id="filter-technician-label">Technician</InputLabel>
                    <Select
                        labelId="filter-technician-label"
                        id="filter-technician"
                        label="Technician"
                        fullWidth
                        value={navTech}
                        size="small"
                        onChange={handleTechFilter}
                    >
                        <MenuItem value={0}>All techs</MenuItem>
                        {technicians.map((technician) => (
                            <MenuItem key={'tech-filter-'+technician.account_id} value={technician.account_id}>
                                {technician.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl></div>
            )}
        </Menu>
    </>
);
};

export default JobsFilter;
