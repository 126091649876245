import { createContext, useContext, useEffect, useState, useCallback } from "react";
import { req, log } from '../utils'; // Assuming this is correctly importing your request utility
import { useParams } from "react-router-dom";
import { getFormattedWeekDates } from "../utils";
const DataContext = createContext(null);

function DataProvider({ children }) {
  const adminIds = [1, 999,910,1094,702,651, 691,653,1154];
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [jobs, setJobs] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [machines, setMachines] = useState([]);
  const [parts, setParts] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [venueLocations, setVenueLocations] = useState([]);
  const use_cached = false;//flip on and off cache on is for faster dev
  const [scheduleFilter, setScheduleFilter] = useState(() => {
    // Retrieve the filter from local storage or default to 'NOT COMPLETED'
    return localStorage.getItem('_bc_schedule_filter') || 'NOT COMPLETED';
  });
  const [techFilter, setTechFilter] = useState(0);

  useEffect(() => {
    // Store the scheduleFilter in local storage whenever it changes
    localStorage.setItem('_bc_schedule_filter', scheduleFilter);
  }, [scheduleFilter]);

  useEffect(() => {
    //if the account_id is in the adminIds array then set isAdmin to true
    const account_id = localStorage.getItem('_bc_tech_user_id');
    if (account_id && adminIds.includes(parseInt(account_id))) {
      setIsAdmin(true);
    }
  }, []);
  const batchUpdate = useCallback((updates) => {
    updates.forEach(({ objectType, id, property, value }) => {
      if (objectType === "job") {
        setJobs(prevJobs => prevJobs.map(job =>
          String(job.service_call_id) === String(id) ? { ...job, [property]: value } : job
        ));
      } else if (objectType === "machine") {
        setMachines(prevMachines => prevMachines.map(machine =>
          String(machine.machine_id_pk) === String(id) ? { ...machine, [property]: value } : machine
        ));
      }
      // Add more object types as needed
    });
  }, []);

  const updateProperty = useCallback((objectType, id, name, value) => {
    try {
      if (objectType === "job") {
        let job = jobs.find(job => String(job.service_call_id) === String(id));
        const updatedJobs = jobs.map(job => String(job.service_call_id) === String(id) ? { ...job, [name]: value } : job);
        job = updatedJobs.find(job => String(job.service_call_id) === String(id));
        setJobs(updatedJobs);
      } else if (objectType === "machine") {
        const updatedMachines = machines.map(machine => String(machine.machine_id_pk) === String(id) ? { ...machine, [name]: value } : machine);
        setMachines(updatedMachines);
      }

      // Add logic for other object types as necessary
    } catch (error) {
      log("updateProperty", error);
    }
  }, [jobs, machines]);
  const addContextElement = (objectType, data) => {
    if (objectType === "job") {
      setJobs([...jobs, data])
    }
    if (objectType === "venueLocations") {
      setVenueLocations([...venueLocations, data])
    }
  }
  const appendJobLog = useCallback((jobId, logEntry) => {
    try {
      const updatedJobs = jobs.map(job => {
        if (String(job.service_call_id) === String(jobId)) {
          // Ensure job.log is always an array
          const log = Array.isArray(job.log) ? job.log : [];
          return { ...job, log: [...log, logEntry] };
        }
        return job;
      });
      setJobs(updatedJobs);
    } catch (error) {
      log("appendJobLog", error);
    }
  }, [jobs]);
  

  const localStorageKeys = {
    jobs: "_bc_jobs",
    machines: "_bc_machines",
    parts: "_bc_parts",
    schedule: "_bc_schedule",
    venueLocations: "_bc_venueLocations",
    technicians: "_bc_technicians"
  };

  const autoParseJSON = (value) => {
    //if it doesn't look like a JSON object, return the value
    if (typeof value !== 'string' || !value || (!value.trim().startsWith("{") && !value.trim().startsWith("["))) {
      // Return the value directly if it's not a string, empty, or doesn't look like JSON
      return value;
    }
    try {
      const parsed = JSON.parse(value);
      if (Array.isArray(parsed) || (typeof parsed === 'object' && parsed !== null)) {
        return parsed;
      } else {
        return value; // Fallback to original value if not array or object
      }
    } catch(e) {
      console.warn("Error autoParseJSON", value,e);
      if (value.trim().startsWith("[")) {
        return []; // Fallback to empty array
      } else if (value.trim().startsWith("{")) {
        return {}; // Fallback to empty object
      } else {
        return value; // Fallback to original value
      }
    }
  };

  const processAndSetFetchedData = (key, data) => {
  
    try {
      const processedData = data.map(item => {
        return Object.entries(item).reduce((acc, [propKey, propValue]) => {
          acc[propKey] = typeof propValue === 'string' ? autoParseJSON(propValue) : propValue;
          return acc;
        }, {});
      });

      switch (key) {
        case 'jobs': setJobs(processedData); break;
        case 'machines': setMachines(processedData); break;
        case 'parts': setParts(processedData); break;
        case 'schedule': setSchedule(processedData); break;
        case 'venueLocations': setVenueLocations(processedData); break;
        case 'technicians': setTechnicians(processedData); break;
        default: break;
      }
    } catch (error) {
      log("processAndSetFetchedData", error);
    }
  };
  const { date } = useParams();
  const startOfWeek = getFormattedWeekDates(date ? date : null)[0];
  const fetchDataAndUpdateState = async (key, endpoint) => {
    try {
      let data;

      if (use_cached) {
        const cachedData = localStorage.getItem(localStorageKeys[key]);
        if (cachedData) {
          data = JSON.parse(cachedData);
          processAndSetFetchedData(key, data); // Use cached data
          return; // Exit the function early as we don't need to fetch from the server
        }
      }

      // If no cached data or use_cached is false, proceed to fetch from the server
      const response = await req('GET', endpoint);
      if (response) {
    
        processAndSetFetchedData(key, response); // Process data before setting state
        const dataString = JSON.stringify(response);

        // Check if data exceeds maximum local storage size
        const maxSize = 5 * 1024 * 1024; // 5MB
        if (dataString.length <= maxSize) {
          localStorage.setItem(localStorageKeys[key], dataString); // Store new value
        } else {
          log("setLocalStorage", `Data size exceeds maximum limit of ${maxSize} bytes`);
        }
      } else {
        throw new Error(`Failed to fetch ${key}`);
      }
    } catch (error) {
      log("fetchDataAndUpdateState", {error,key, endpoint});
    }
  };


  const fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        fetchDataAndUpdateState('jobs', 'get_jobs_with_images'), // Ensure these endpoints are correct
        fetchDataAndUpdateState('machines', 'get_machines'),
        fetchDataAndUpdateState('parts', 'get_parts'),
        fetchDataAndUpdateState('schedule', 'get_schedule'),
        fetchDataAndUpdateState('venueLocations', 'get_venue_locations'),
        fetchDataAndUpdateState('technicians', 'get_technicians')
      ]);

      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(true);
      setErrorMessage('Failed to fetch data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    const handleOnlineStatusChange = () => setIsOnline(navigator.onLine);
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  const contextValue = {
    isOnline,
    loading,
    error,
    errorMessage,
    jobs,
    setJobs,
    machines,
    setMachines,
    parts,
    setParts,
    schedule,
    setSchedule,
    venueLocations,
    setVenueLocations,
    updateProperty,
    appendJobLog, 
    addContextElement, 
    startOfWeek,
    batchUpdate,
    scheduleFilter, 
    setScheduleFilter, 
    isAdmin,
    technicians,
    setTechFilter,
    techFilter
  };

  return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
}

function useDataContext() {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
}

export { DataProvider, useDataContext };
