import { Chip } from '@mui/material';

export function SuggestedMessagesChips({ suggestedMessages, handleSuggestionClick }) {
    
    return (
        <div style={{ marginBottom: '.5rem' }}>
            {suggestedMessages.map((message, index) => (
                <Chip
                    key={index}
                    label={message}
                    variant="outlined"
                    className="bc-chip"
                    onClick={() => handleSuggestionClick(message)} // Pass message to handleSuggestionClick
                />
            ))}
        </div>
    );
}