import { useDataContext } from '../../contexts/DataContext';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Button, Stack } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export function PagingButtons() {
    const { startOfWeek } = useDataContext();
    const handleButtonClick = () => {
        // Scroll to the top of the page when the button is clicked
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{  width: "100%" }}
            >
                <Button
                    variant="outlined"
                    color="persiangreen"
                    startIcon={<ChevronLeft />}
                    sx={{
                        maxWidth: "188px",
                        width: "50%"
                    }}
                    component={Link}
                    to={"/schedule/" + moment.utc(startOfWeek).subtract(7, 'd').format('YYYY-MM-DD')}
                    onClick={handleButtonClick} // Scroll to the top of the page when the button is clicked
                >Previous</Button>
                <Button
                    variant="outlined"
                    color="persiangreen"
                    endIcon={<ChevronRight />}                
                    sx={{
                        maxWidth: "188px",
                        width: "50%"
                    }}
                    component={Link}
                    to={"/schedule/" + moment.utc(startOfWeek).add(7, 'd').format('YYYY-MM-DD')}
                    onClick={handleButtonClick} // Scroll to the top of the page when the button is clicked
                >Next</Button>
            </Stack>
        </footer>
    );
}