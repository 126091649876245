import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Snackbar } from '@mui/material/';

import { req, log } from '../../utils';
import { useDataContext } from '../../contexts/DataContext';

const BinReplacement = ({ job }) => {
    const [qtyOfBins, setQtyOfBins] = useState(0);
    const { appendJobLog } = useDataContext();
    const [submitted, setSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [binsCollected, setBinsCollected] = useState([]);
    const [defaultBinsQty, setDefaultBinsQty] = useState(0);
    const [snack, setSnack] = useState({
        open: false,
        message: '',
    });

    const handleQtyChange = (e) => {
        setQtyOfBins(parseInt(e.target.value, 10));
    };

    // Updated to handle submission with or without geolocation data
    const handleSubmission = (lat = '0', long = '0', accuracy = '0') => {
        try {
            const data = {
                venue_id: job.venue_id,
                service_call_id: job.service_call_id,
                qty_of_bins: qtyOfBins,
                lat: lat,
                long: long,
                accuracy_in_meters: accuracy,
            };
            setSubmitted(true);
            req('post', 'bins_replaced', data);

            appendJobLog(job.service_call_id, {
                event: "bins_replaced",
                time: Math.floor(Date.now() / 1000),
                details: {
                    qty_of_bins: qtyOfBins,
                    lat: lat,
                    long: long,
                    accuracy_in_meters: accuracy,
                }
            });
            setSnack({ open: true, message: 'Bins replaced' });
            setQtyOfBins(0);
            setDefaultBinsQty(0);
            setSubmitted(false);

        } catch (e) {
            logError('Error collecting bins', e);
        }
    };

    const handleGeolocationSuccess = (position) => {
        const { latitude, longitude, accuracy } = position.coords;
        handleSubmission(String(latitude), String(longitude), String(accuracy));
    };

    const handleGeolocationError = (error) => {
        console.warn(`ERROR(${error.code}): ${error.message}`);
        // Fallback to 0 values for lat and long on error
        handleSubmission();
        setSnack({ open: true, message: 'Error obtaining location. Bins replacement without location data.' });
        logError('Error obtaining location', error);
    };

    const handleSubmit = () => {
        if (qtyOfBins > 0) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError, {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                });
            } else {
                // Directly submit with 0 values if geolocation is not supported
                handleSubmission();
                setSnack({ open: true, message: 'Geolocation is not supported by this browser. Bins collected without location data.' });
            }
        } else {
            setSnack({ open: true, message: 'Please add quantity first!' });
        }
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({ ...snack, open: false });
    };

    const logError = (subject, details) => {
        // Add your logging mechanism here
        log(subject, details);
    };

    useEffect(() => {
        if (job?.log && Array.isArray(job.log)) {
            setBinsCollected(job.log.filter((log) => log.event === 'bins_replaced'));
        }
    }, [job]);

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
                <TextField
                    autoFocus
                    id="qtyBins"
                    name="qtyBins"
                    type="number"
                    minval={0}
                    placeholder="# damaged bins"
                    label="# damaged bins replaced?"
                    fullWidth
                    color="persiangreen"
                    disabled={submitted}
                    onChange={handleQtyChange}
                    defaultValue={defaultBinsQty}
                    InputProps={{
                        sx: {
                            height: '56px',
                            '& .MuiInputBase-input': {
                                textAlign: 'center',
                            },
                        }
                    }}
                />
                <div style={{ marginTop: "1rem" }}>
                    <div style={{ fontWeight: '700' }}>{successMessage}</div>
                    <Button
                        variant="contained"
                        className={submitted ? 'btn-submit btn-persiangreen-hover' : 'btn-submit'}
                        color="persiangreen"
                        disabled={submitted}
                        disableElevation={true}
                        required={true}
                        onClick={handleSubmit}
                        fullWidth
                    >
                        {submitted ? 'Recording bin replacement' : 'Damaged bins'}
                    </Button>
                </div>
                <div style={{ textAlign: 'left', display: 'block', marginTop: "1rem" }}>
                    {binsCollected.length === 0 ? null :
                        (<div className='feedback-sent-title'>Damaged bins replaced</div>)}
                    {binsCollected.map((item) => {
                        return (
                            <div className={"feedback"} key={item.time}>
                                <div className='message'>{item.details.qty_of_bins} {item.details.qty_of_bins > 1 ? 'bins' : 'bin'} replaced</div>
                                <div className='date'>{new Date(item.time * 1000).toLocaleString()}</div>
                            </div>
                        )
                    })}
                </div>
            </Grid>
            <Snackbar
                open={snack.open}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                message={snack.message}
            />
        </Grid>
    );
};
export default BinReplacement;
