import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDataContext } from "../contexts/DataContext";
import { AppBar, CircularProgress, Container } from "@mui/material";
import DayComponent from "../components/schedule/DayComponent";
import { PagingButtons } from "../components/schedule/PagingButtons";
import WeekDayButtons from "../components/schedule/WeekDayButtons";
import { useLocation, useParams } from "react-router-dom";

const Schedule = () => {
  const { date } = useParams();
  const { error, errorMessage, jobs,  isAdmin,scheduleFilter,techFilter } = useDataContext();
  //console.log("Shedual techFilter",techFilter)
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(true); // [1
  const [weekDates, setWeekDates] = useState(getWeekDays());
  const location = useLocation(); // Use useLocation to react to changes in the URL
  const account_id = localStorage.getItem('_bc_tech_user_id');
  function filterJobsByUserAndDate(jobs,  startMoment, endMoment) {
    console.log("filterJobsByUserAndDate",jobs);
    return jobs.filter((job) => {
      const jobDateMoment = moment(job.date, "YYYY-MM-DD");
      const isWithinWeek = jobDateMoment.isSameOrAfter(startMoment, "day") && jobDateMoment.isSameOrBefore(moment(startMoment).add(6, "days"), "day");
      let filteredByStatus = true;
      if(scheduleFilter==="NOT COMPLETED")
        filteredByStatus = job.status !== 'COMPLETED';
      if(scheduleFilter==="COMPLETED")
        filteredByStatus = job.status === 'COMPLETED';
      //if tehcFilter is not 0 then filter by tech_id
      if(techFilter !== 0 && techFilter !== undefined)
        return isWithinWeek && String(job.tech_id) === String(techFilter) && filteredByStatus;
      if(isAdmin)
        return isWithinWeek&&filteredByStatus;
      const isForUser = String(job.tech_id) === String(account_id);
        return isWithinWeek && isForUser&&filteredByStatus;
    });
  }
 


  function getWeekDays(date) {
    const startOfWeek = moment(date).startOf("isoWeek");
    return Array.from({ length: 7 }).map((_, index) =>
      startOfWeek.clone().add(index, "days").format("YYYY-MM-DD")
    );
  }

  useEffect(() => {
    if(jobs.length > 0) {
        setLoadingJobs(false);
        }
    // Determine the start date either from the URL or the current date
    // Set the week dates based on the determined start date
    let startDate = date
      ? moment(date, "YYYY-MM-DD").startOf("isoWeek")
      : moment().startOf("week"); // or 'week' for weeks starting on Sunday

    let urlDate = location.pathname.split("/").pop();
    if (urlDate && moment(urlDate, "YYYY-MM-DD").isValid()) {
      startDate = moment(urlDate, "YYYY-MM-DD");
    }
    setWeekDates(
      Array.from({ length: 7 }).map((_, index) =>
        startDate.clone().add(index, "days").format("YYYY-MM-DD")
      )
    );

    // Filter the jobs based on the new week dates
    const filtered = filterJobsByUserAndDate(
      jobs,
      startDate.format("YYYY-MM-DD")
    );

    setFilteredJobs(filtered);
  }, [date, jobs, account_id, isAdmin, location.pathname, scheduleFilter,techFilter]);
//console.log("filteredJobs",filteredJobs)
return (
    <main className="bc-page--content">
        <Container maxWidth="lg" className="schedule-page">
            {loadingJobs ? (<div className="text-center">
                <CircularProgress color="persiangreen" />
                </div>
            ) : error ? (
                <div>{errorMessage}</div>
            ) : (
                <div style={{ position: "sticky", top: 0 }}>
                    <AppBar id="days-buttons" color="transparent" position="sticky" sx={{paddingTop:"60px",paddingBottom:"5px",borderBottom:"1px solid #00A49B"}} elevation={0}>
                        <h1>Schedule</h1>
                        <WeekDayButtons />
                    </AppBar>
                    {weekDates.map((date, index) => (
                        <DayComponent key={index} date={date} jobs={filteredJobs} />
                    ))}
                    <PagingButtons />
                </div>
            )}
        </Container>
    </main>
);
};

export default Schedule;
