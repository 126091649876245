import React, { useEffect, useState, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useDataContext } from '../../contexts/DataContext';
import { req } from '../../utils';

const PartsUsed = ({ job }) => {
    const { parts, updateProperty } = useDataContext();
    const [selectedParts, setSelectedParts] = useState([]);
    const [partsData, setPartsData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const persistNeeded = useRef(false);

    // Initialize from job.parts_used only on mount or when job.parts_used changes significantly
    useEffect(() => {
        const initialSelectedParts = job.parts_used?.map(partUsed => {
            const partDetail = parts.find(part => String(part.part_id_pk) === String(partUsed.part_id_pk));
            return partDetail ? { ...partDetail, qty: partUsed.qty } : null;
        }).filter(part => part !== null);
        if (initialSelectedParts) {
            setSelectedParts(initialSelectedParts);
        }
    }, [job.parts_used, parts]);

    // This effect updates partsData based on the current selectedParts
    useEffect(() => {
        const filteredParts = parts.filter(part => 
            !selectedParts.some(selected => String(selected.part_id_pk) === String(part.part_id_pk))
        );
        setPartsData(filteredParts);
    }, [parts, selectedParts]);

    // Effect to persist parts when needed
    useEffect(() => {
        if (persistNeeded.current) {
            const parts_data = selectedParts.map(part => ({ part_id_pk: part.part_id_pk, qty: part.qty }));
            updateProperty('job', job.service_call_id, 'parts_used', parts_data);
            req('POST', 'set_job_parts', { service_call_id: job.service_call_id, parts: JSON.stringify(parts_data) });
            persistNeeded.current = false; // Reset the flag after persisting
        }
    }, [selectedParts, updateProperty, job.service_call_id]);

    const handleAddPart = (event, value) => {
        if (value) {
            setSelectedParts(prev => [...prev, { ...value, qty: 1 }]);
            setInputValue('');
            persistNeeded.current = true;
        }
    };

    const handleRemovePart = (partId) => {
        setSelectedParts(prev => prev.filter(part => String(part.part_id_pk) !== String(partId)));
        persistNeeded.current = true;
    };

    const handleQuantityChange = (event, partId) => {
        setSelectedParts(prev => prev.map(part =>
            String(part.part_id_pk) === String(partId) ? { ...part, qty: parseInt(event.target.value, 10) } : part
        ));
        persistNeeded.current = true;
    };

    return (
        <div>
            <Autocomplete
                id="parts-autocomplete"
                options={partsData}
                getOptionLabel={(option) => `${option.name} - ${option.category}`}
                renderInput={(params) => (
                    <TextField {...params} label="Add parts" variant="outlined" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                )}
                onChange={handleAddPart}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                clearOnEscape
            />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Part Name</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Remove</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedParts.map((part, index) => (
                            <TableRow key={index}>
                                <TableCell>{part.name}</TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={part.qty}
                                        onChange={(event) => handleQuantityChange(event, part.part_id_pk)}
                                        inputProps={{ min: 1, style: { textAlign: 'center' } }}
                                        style={{ width: '60px' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleRemovePart(part.part_id_pk)}>Remove</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default PartsUsed;
