import React, { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    TextField,
    Button,
} from "@mui/material";
import {log, req} from "../utils";
import { useDataContext } from "../contexts/DataContext";
const Reschedule = ({ call }) => {
 
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState("");
    const { updateProperty} = useDataContext();
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        console.log("RESCHEDULE", {id:call?.service_call_id,reason});
        //log("Reschedule call", call);
        req("POST", "reschedule", {id:call?.service_call_id,reason})
        //update call in page context
        updateProperty("job", call.service_call_id, "status","RESCHEDULE");
        //update context with new job
        setOpen(false);
        //hide modal
    
    };
    return (
        <>
            <Button
                variant="outlined"
                style={{ marginTop: "10px" }}
                fullWidth
                color="persiangreen"
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
            >
                Reschedule call
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="popup-dialog-title"
                onClick={(e) => e.stopPropagation()}
                sx={{
                    "& .MuiDialog-paper": {
                        margin: "0",
                        maxHeight: "calc(100% - 64px)",
                        maxWidth: "calc(100% - 64px)",
                        width: "100%",
                       
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    },
                }}
            >
                <DialogTitle id="popup-dialog-title">
                    Reschedule call: {call?.bc_call_code}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Please include any details for the reschedule here"
                            fullWidth
                            color="persiangreen"
                            multiline={true}
                            minRows={4}
                            onChange={(e) => setReason(e.target.value)}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="persiangreen">
                        Cancel
                    </Button>
                    <Button variant="contained" color="persiangreen" onClick={handleSubmit}>
                        Reschedule call
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Reschedule;
