import React, { useState, useEffect, useMemo,useCallback  } from 'react';
import { Grid } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { req, log,parseJsonProperties } from '../../utils';
import { SiteAuditForm } from './SiteAuditForm';
import { useDataContext } from '../../contexts/DataContext';
export function JobRelatedFiles({ job,setFilesCount, setSafImages }) {

    const [siteAuditFormData, setSiteAuditFormData] = useState([]);

    useEffect(() => {
        const getSiteAuditForms = async () => {
            try {
                const response = await req('GET', 'get_site_audit_files', { venue_id: job.venue_id });
                const parsedForms = response.map(form => parseJsonProperties(form));
                setSiteAuditFormData(parsedForms);
    
                let images = [];
    
                parsedForms.forEach(form => {
                    Object.keys(form).forEach(key => {
                        if (key.includes("img")) {
                            // If the key is an array, iterate over the items
                            if (Array.isArray(form[key])) {
                                form[key].forEach(item => {
                                    if (item && item !== "null") images.push(item);
                                });
                            } else if (form[key] && form[key] !== "null") {
                                // If the key is a single image URL
                                images.push(form[key]);
                            }
                        }
                        
                        // Handle nested objects or arrays
                        if (typeof form[key] === 'object' && form[key] !== null) {
                            Object.values(form[key]).forEach(nestedValue => {
                                if (Array.isArray(nestedValue)) {
                                    nestedValue.forEach(nestedItem => {
                                        if (typeof nestedItem === 'object') {
                                            Object.keys(nestedItem).forEach(nestedKey => {
                                                if (nestedKey.includes("img") && nestedItem[nestedKey] && nestedItem[nestedKey] !== "null") {
                                                    images.push(nestedItem[nestedKey]);
                                                }
                                            });
                                        }
                                    });
                                } else if (typeof nestedValue === 'string' && nestedValue.includes("/saf_")) {
                                    images.push(nestedValue);
                                }
                            });
                        }
                    });
                });
    
                //console.log("Extracted Images:", images);
                setSafImages(images); // Assuming you have this state to store images
    
            } catch (error) {
                log("Error getting site audit", error);
            }
        };
    
        if (job?.venue_id) {
            getSiteAuditForms();
        }
    
    }, [job?.venue_id]);
    

    const generateFileLink = useCallback((url) => {
        try {
            if (url.startsWith('venue-files')) {
                const fileName = url.split('/').pop();
                return (
                    <a href={url} target="_blank" rel="noopener noreferrer" download>
                        <FileDownloadIcon /> {fileName}
                    </a>
                );
            } else if (url.includes('/assets/pdf/')) {
                const fileName = url.split('/').pop();
                return (
                    <a href={url} target="_blank" rel="noopener noreferrer" download>
                        <FileDownloadIcon /> {fileName}
                    </a>
                );
            } else {
                return (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <OpenInNewIcon /> Open
                    </a>
                );
            }
        } catch (error) {
            log("Error generating file link", error);
        }
    }, []);
    // Non-image files
    const nonImageFiles = useMemo(() => job?.files_for_technicians?.filter(file =>
        typeof file === 'string' && !/\.(jpg|jpeg|png)$/i.test(file)
    ) || [], [job?.files_for_technicians]);
    //push the manual in there
    if(!nonImageFiles.includes('/assets/pdf/BCAM002_Safe_Operating_Manual.pdf'))
        nonImageFiles.push('/assets/pdf/BCAM002_Safe_Operating_Manual.pdf');
    
    const nonImageFileLinks = nonImageFiles.map((file, index) => (
        <div key={index}>
            {generateFileLink(file)}
        </div>
    ));

  

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h4>Venue files</h4>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{ marginBottom: '10px' }}>
                        {siteAuditFormData.map((form, index) => (
                            <SiteAuditForm key={index} siteAuditForm={form} />
                        ))}
                    </div>
                    {nonImageFileLinks}
                </Grid>
            
            </Grid>
        </>
    );
}
export default JobRelatedFiles;