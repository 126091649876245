import React, { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  ButtonGroup,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useDataContext } from "../../contexts/DataContext";
import { req, log } from "../../utils";
import CompleteJobMachineAssessment from "./CompleteJobMachineAssessment";

export const CompleteJob = ({ job }) => {
  //console.log("CompleteJob", job);
  const current_technician = localStorage.getItem("_bc_tech_user_id");
  const requires_bottle_count = [
    //serices that require bottle  counts
    "BREAKDOWN",

    "Machine relocation",
    "Maintenance Request",
    "Preventive maintenance",
    "Preventative Maintenance",
  ];

  const { updateProperty, machines } = useDataContext();
  const [open, setOpen] = useState(false);
  const [machineAssessments, setMachineAssessments] = useState([]);
  const [jobName, setJobName] = useState("");
  const initialyExpanded = job?.machine_ids_array.length >= 1 ? job.machine_ids_array[0] : null;
  const [expandedAccordion, setExpandedAccordion] = useState(initialyExpanded);
  useEffect(() => {
    setJobName(job?.bc_call_code || job?.service_call_id);
    //get browser location and set it to the job
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("position", position);
        updateProperty("job", job.service_call_id, "location", {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          accuracy: position.coords.accuracy,
        });
      },
      (error) => {
        updateProperty("job", job.service_call_id, "location", {
          lat: 0,
          lng: 0,
          accuracy: 0,
        });
        alert("Unable to retrieve current position.");
      }
    );
    
  }, []);
  const getMachineSerial = (machine_id) => {
    const machine = machines.find(
      (machine) => String(machine.machine_id_pk) === String(machine_id)
    );
    return machine?.serial_id;
  };
  const showError = (machine_id, message) => {
    setExpandedAccordion(machine_id); // Open the accordion
    alert(message);  
  };
  const validate_forms = () => {
    //loop through all machine assessments and check if they are valid
 
    let validated_foms = 0;
    for (let i = 0; i < machineAssessments.length; i++) {
      if(machineAssessments[i].not_serviced){
        continue;
      }
      let serial = getMachineSerial(machineAssessments[i].machine_id);
      if (machineAssessments[i].machine_rating===0) {
        showError(machineAssessments[i].machine_id, `Please rate the machine ${serial} - 1 to 5 stars `);
        return false;
      }
      if (!machineAssessments[i].machine_cleaned){
        showError(machineAssessments[i].machine_id, `Please verify that you cleaned ${serial}`);
        return false;
      }
      if (!machineAssessments[i].machine_inspection_completed) {
        showError(machineAssessments[i].machine_id, `Please verify that you inspected ${serial}`);
        return false;
      }
    
      if(requires_bottle_count.includes(job.service_call_type)){
        if (machineAssessments[i].bottle_count===0) {
          showError(machineAssessments[i].machine_id, `Machine: ${serial} Please add bottle count`);
          return false;
        }
        if (machineAssessments[i].previous_months_bottle_count===0) {
          showError(machineAssessments[i].machine_id,`Machine: ${serial} Please add previous bottle count`);
          return false;
        }
      }
      if(machineAssessments[i].machine_tagged){
        //if tagged make sure they included a tag
        if (machineAssessments[i].machine_tag==="") {
          showError(machineAssessments[i].machine_id,`Machine: ${serial} Please add a tag id`);
          return false;
        }
        if (machineAssessments[i].machine_tagged_date==="") {
          showError(machineAssessments[i].machine_id,`Machine: ${serial} Please add a tag date`);
          return false;
        }
        if (machineAssessments[i].machine_tag_due_date==="") {
          showError(machineAssessments[i].machine_id,`Machine: ${serial} Please add a tag due date`);
          return false;
        }
      }
      //if greg wants images to be required this is where it goes
      if(machineAssessments[i].images.length===0){
        showError(machineAssessments[i].machine_id,`Machine: ${serial} Please add images of the machine`);
        return false;
      }
      if(machineAssessments[i].completion_notes===""){
        alert(`Machine: ${serial} Please add completion notes`);
        return false;
      }
      validated_foms++;
    }
  
    return true;
  };
  const handleSubmission = () => {
    //todo validation of at at least one machine
    if(!validate_forms()){
      console.log("validation failed");
      return;
    }
    const post_data = {
      service_call_id: job.service_call_id,
      location: JSON.stringify(job.location),
      assessments: JSON.stringify(machineAssessments),
    };
    console.log("post_data", machineAssessments, post_data);
    req("POST", "complete_job_v2", post_data);
    setOpen(false);
    //set local context of job to completed
    updateProperty("job", job.service_call_id, "status", "completed");
  };
  const updateMachineReview = (assessment, formValid) => {
    //if muliple assessments we need at least one to have a valid review
    
    //look for machine by id if not found add it otherwise update it
    for (let i = 0; i < machineAssessments.length; i++) {
      if (
        String(machineAssessments[i].machine_id) ===
        String(assessment.machine_id)
      ) {
        machineAssessments[i] = assessment;
        return;
      }
    }
    machineAssessments.push(assessment);
    //if all machines forms are valid or they don't need to be then  allow submission of form
    console.log("newMachineAssessments", machineAssessments);
  };
  return (
    <div>
      {job?.machine_ids_array.length !== 0 && (
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          fullWidth={true}
          color="persiangreen"
        >
          Complete job
        </Button>
      )}

      <Dialog
        open={open}
        fullScreen
        onClose={() => setOpen(false)}
        sx={{ textAlign: "center" }}
      >
        <DialogTitle>Completion Summary: {jobName}</DialogTitle>
        <DialogContent>
          {job?.machine_ids_array.length === 1 ? (
            <CompleteJobMachineAssessment
              job={job}
              machine_id={job.machine_ids_array[0]}
              machine={job.machine_ids_array[0]}
              updateMachineReview={updateMachineReview}
              requires_bottle_count={requires_bottle_count}
              allow_no_review={false}
            />
          ) : (
            job?.machine_ids_array.map((machineId,index) => (
              <Accordion
                key={machineId}
                expanded={expandedAccordion === machineId}
                onChange={() =>
                  setExpandedAccordion(
                    expandedAccordion === machineId ? null : machineId
                  )
                }
              >
                <AccordionSummary>
                  <Typography>
                    Assessment: {getMachineSerial(machineId)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CompleteJobMachineAssessment
                    job={job}
                   //machine in loop
                    machine={job.machine_ids_array[index]}
                    machine_id={machineId}
                    updateMachineReview={updateMachineReview}
                    requires_bottle_count={requires_bottle_count}
                    allow_no_review={false}
                  />
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </DialogContent>
        <DialogActions>
          <ButtonGroup fullWidth>
            <Button
              variant="contained"
              style={{ width: "25%", color: "white" }}
              onClick={() => setOpen(false)}
              color="grey"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmission}
              variant="contained"
              color="persiangreen"
              style={{ width: "75%" }}
            >
              Verify Completion
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};
