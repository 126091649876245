import moment from "moment";
import { Link } from "react-router-dom";
import { Grid } from '@mui/material/';
import { log } from "../../utils";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

export function JobHeader({ job }) {
    const handleLinkClick = (e) => {
        e.stopPropagation();
        try {
            // Log the link click event
            //log("Link Clicked", e.target.href);
        } catch (error) {
            // Log the error if logging fails
            log("Error Logging Link Click", error);
        }
    };

    return (
        <>
            <section className="section--job-summary">
                <h3>{job.service_call_type ? job.service_call_type : "Service Call"}</h3>
                <h3>{job.venue_name}</h3>
                <Grid container className="font-smaller">
                    <Grid xs={12} sm={6} item>
                        <address>
                            {job.address}<br />
                            {job.suburb} {job.postal_code} {job.deposit_region}
                        </address>
                        <div className="flex flex-row items-center">
                            <span className="text-muted" style={{ marginRight: ".5rem" }}>Contact:</span>
                            {job?.contact_name}
                            {job.contact_phone && (
                                <>
                                    {<span style={{ marginLeft: ".75rem" }}>·</span>}
                                    <Link
                                        className="flex flex-row items-center"
                                        style={{ marginLeft: ".75rem" }}
                                        to={`tel:${job.contact_phone}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={handleLinkClick}
                                    >
                                        <PhoneIphoneIcon sx={{ fontSize: 12, marginRight: ".125rem" }} />{job.contact_phone}
                                    </Link>
                                </>
                            )}
                            {job.contact_email && (
                                <>
                                    {job.contact_email.includes(",") ? (
                                        job.contact_email.split(",").map((email, index) => (
                                            <Link
                                                key={index}
                                                className="flex flex-row items-center"
                                                style={{ marginLeft: ".75rem" }}
                                                to={`mailto:${email.trim()}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={handleLinkClick}
                                            >
                                                <MailOutlineIcon sx={{ fontSize: 16, marginRight: ".125rem" }} />
                                            </Link>
                                        ))
                                    ) : (
                                        <Link
                                            className="flex flex-row items-center"
                                            style={{ marginLeft: ".75rem" }}
                                            to={`mailto:${job.contact_email}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={handleLinkClick}
                                        >
                                            <MailOutlineIcon sx={{ fontSize: 16, marginRight: ".125rem" }} />
                                        </Link>
                                    )}
                                </>
                            )}
                            {job.contact_number && (
                                <>
                                    <Link
                                        className="flex flex-row items-center"
                                        style={{ marginLeft: ".75rem" }}
                                        to={`tel:${job.contact_number}`}
                                        target="_blank"
                                        onClick={handleLinkClick}
                                    >
                                        <PhoneIphoneIcon sx={{ fontSize: 16, marginRight: ".125rem" }} />
                                    </Link>
                                </>
                            )}
                        </div>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ textAlign: { xs: "left", sm: "right" } }}>
                        <div><span className="text-muted">Assigned by:</span> {job.created_by_name}</div>
                        <div><span className="text-muted">Promised:</span> {moment(job.promise_date * 1000).format('MMMM DD, YYYY')}</div>
                        <div><span className="text-muted" style={{ textTransform: "capitalize" }}>Status:</span> <span style={{ textTransform: "capitalize" }}>{job.status}</span></div>
                    </Grid>
                </Grid>
            </section>
        </>
    );
}

export default JobHeader;