/**
 * This file is simply the router for the entire application.
 */
import { Navigate, Route, Routes } from "react-router-dom";
import { log } from "./utils";
// CSS & Themes
import "./assets/css/default.css";
import { grey } from '@mui/material/colors';

// Layouts
import DataLayout from "./layouts/DataLayout";
import { AppLayout } from "./layouts/AppLayout";

// Pages
import Schedule  from "./pages/Schedule";
import { Job } from "./pages/Job";
import Debug  from "./pages/Debug";


import ProtectedRoute from "./components/ProtectedRoute";

// Theme
import { createTheme, ThemeProvider } from '@mui/material';

let theme = createTheme({
    // Theme customization goes here as usual, including tonalOffset and/or
    // contrastThreshold as the augmentColor() function relies on these
});

theme = createTheme(theme, {
    palette: {
        grey: theme.palette.augmentColor({
            color: {
              main: grey[500],
            },
            name: 'grey',
        }),
        persiangreen: theme.palette.augmentColor({
            color: {
              main: '#00A49B',
            },
            name: 'persiangreen',
        }),
        tarawera: theme.palette.augmentColor({
            color: {
              main: '#063852',
            },
            name: 'tarawera',
        }),
    },
});

function App() {
    try {
        return (
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route element={<ProtectedRoute />}>
                        <Route element={<DataLayout />}>
                            <Route path="/" element={<AppLayout />}>
                                <Route path="" element={<Navigate to="schedule" />} />
                                <Route path="schedule/:date?" element={<Schedule />} />
                                <Route path="call/:service_call_id" element={<Job />} />
                                <Route path="debug" element={<Debug />} />
                            </Route>
                        </Route>
                    </Route>
            
                    <Route path="*" element={<div>404</div>} />
                </Routes>
            </ThemeProvider>
        );
    } catch (error) {
        log("error", "App.js", 0, "Error: " + error.message, error.stack);
    }
}

export default App;
