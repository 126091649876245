import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoIcon from '@mui/icons-material/Info';
import { ImageGallery } from '../images/ImageGallery';

export const SiteAuditForm = ({ siteAuditForm }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Button
                color='persiangreen'
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    paddingLeft: 0,
                }}
            >
                <InfoIcon sx={{ marginRight: '.5rem', }} />
                Site Audit form {siteAuditForm.venue_name}
            </Button>
            <Dialog fullWidth={true} open={open} onClose={handleClose}>
                <DialogTitle>
                    Site Audit Form {siteAuditForm.venue_name}
                    <IconButton edge="end" color='persiangreen' onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='SAF'>
                    {/* Contact Information */}
                    <Accordion key="saf-contact">
                        <AccordionSummary>
                            <Typography variant='h4'>Contact</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography variant="body1">
                                <label>Venue Name:</label> {siteAuditForm.venue_name}
                            </Typography>
                            <Typography variant="body1">
                                <label>Contact:</label> {siteAuditForm.customer_contact_name} {siteAuditForm.email_address && (
                                    <a href={`mailto:${siteAuditForm.email_address}`}>{siteAuditForm.email_address}</a>
                                )}
                            </Typography>
                            {siteAuditForm.phone_number && (
                                <Typography variant="body1">
                                    <label>Phone Number:</label> <a href={`tel:${siteAuditForm.phone_number}`}>{siteAuditForm.phone_number}</a>
                                </Typography>
                            )}
                            <Typography variant="body1">
                                <label>Address:</label> {`${siteAuditForm.street_address}, ${siteAuditForm.suburb}, ${siteAuditForm.state}, ${siteAuditForm.post_code}`}
                                <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${siteAuditForm.street_address}, ${siteAuditForm.suburb}, ${siteAuditForm.state}, ${siteAuditForm.post_code}`)}`}>
                                    <LocationOnIcon color='persiangreen' />
                                </a>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    
                    {/* Details */}
                    <Accordion key="saf-details">
                        <AccordionSummary>
                            <Typography variant="h4">Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        {siteAuditForm.bin_location && (
                                <Typography variant="body1">
                                    <label>Bin location:</label> {siteAuditForm.bin_location}
                                </Typography>
                            )}
                            {siteAuditForm.any_other_notes_regarding_the_installation && (
                                <Typography variant="body1">
                                    <label>Notes:</label> {siteAuditForm.any_other_notes_regarding_the_installation}
                                </Typography>
                            )}
                            {siteAuditForm.shared_collection_poin && (
                                <Typography variant="body1">
                                    <label>Shared collection point:</label> {siteAuditForm.shared_collection_poin}
                                </Typography>
                            )}
                            {siteAuditForm.shared_collection_point_name && (
                                <Typography variant="body1">
                                    <label>Shared collection point name:</label> {siteAuditForm.shared_collection_point_name}
                                </Typography>
                            )}
                            {siteAuditForm.height_clearance && (
                                <Typography variant="body1">
                                    <label>Height clearance:</label> {siteAuditForm.height_clearance}
                                </Typography>
                            )}
                            {siteAuditForm.tight_spaces_for_truck && (
                                <Typography variant="body1">
                                    <label>Tight spaces for truck:</label> {siteAuditForm.tight_spaces_for_truck}
                                </Typography>
                            )}
                            {siteAuditForm.tight_spaces_for_truck_details && (
                                <Typography variant="body1">
                                    <label>Tight spaces for truck details:</label> {siteAuditForm.tight_spaces_for_truck_details}
                                </Typography>
                            )}
                            {siteAuditForm.time_parking_restrictions && (
                                <Typography variant="body1">
                                    <label>Time parking restrictions:</label> {siteAuditForm.time_parking_restrictions}
                                </Typography>
                            )}
                            {siteAuditForm.truck_needs_fob && (
                                <Typography variant="body1">
                                    <label>Truck needs fob:</label> {siteAuditForm.truck_needs_fob}
                                </Typography>
                            )}
                            {siteAuditForm.truck_needs_fob_details && (
                                <Typography variant="body1">
                                    <label>Truck needs fob details:</label> {siteAuditForm.truck_needs_fob_details}
                                </Typography>
                            )}
                            {siteAuditForm.img_bin_collection_area && Array.isArray(siteAuditForm.img_bin_collection_area) && (
                                <div>
                                    <Typography variant="body1">Bin Collection Area</Typography>
                                    <ImageGallery images={siteAuditForm.img_bin_collection_area} maxHeight={50} />
                                </div>
                            )}
                            {siteAuditForm.img_bin_location && Array.isArray(siteAuditForm.img_bin_location) && (
                                <div>
                                    <Typography variant="body1">Bin location</Typography>
                                    <ImageGallery images={siteAuditForm.img_bin_location} maxHeight={50} />
                                </div>
                            )}
                            {siteAuditForm.img_height_clearance && Array.isArray(siteAuditForm.img_height_clearance) && (
                                <div>
                                    <Typography variant="body1">Height clearance</Typography>
                                    <ImageGallery images={siteAuditForm.img_bin_location} maxHeight={50} />
                                </div>
                            )}

                        </AccordionDetails>
                    </Accordion>
                     {/* Removal Details */}
                     {siteAuditForm?.removal_details && typeof siteAuditForm.removal_details === "object" && (
                        <Accordion key="saf-removal-details">
                            <AccordionSummary>
                                <Typography variant="h4">Removal details</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {Object.entries(siteAuditForm.removal_details).map(([key, value], index) => (
                                    <Typography key={`detail-${index}`} variant="body1">
                                        <label>{key.replace(/_/g, ' ')}</label>: {value}
                                    </Typography>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {/* Machines */}
                    <Accordion key="saf-machines">
                        <AccordionSummary>
                            <Typography variant="h4">Machines</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {siteAuditForm?.machines_data?.map((machine, index) => (
                                <div key={`machine-${machine.machine_id || index}`}>
                                    {Object.entries(machine).map(([key, value], valueIndex) => {
                                        const uniqueKey = `machine-${index}-${key}-${valueIndex}`;
                                        if (typeof value === 'string' && value.trim() !== '') {
                                            return <Typography key={uniqueKey} variant="body1"><label>{key.replace(/_/g, " ")}</label>: {value}</Typography>;
                                        }
                                        if (Array.isArray(value) && value.length > 0) {
                                            return (
                                                <div key={uniqueKey}>
                                                    <Typography variant="h4">{key.replace(/_/g, " ")}</Typography>
                                                    <ImageGallery images={value} maxHeight={50} />
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>

                    {/* Risks */}
                    <Accordion key="saf-risks">
                        <AccordionSummary>
                            <Typography variant="h4">Risks</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {siteAuditForm?.risk_assessment_data?.map((risk, index) => (
                                <div key={`risk-${index}`}>
                                    {Object.entries(risk).map(([key, value], valueIndex) => {
                                        const uniqueKey = `risk-${index}-${key}-${valueIndex}`;
                                        if (typeof value === 'string' && value.trim() !== '') {
                                            return <Typography key={uniqueKey} variant="body1"><label>{key.replace(/_/g, " ")}</label>: {value}</Typography>;
                                        }
                                        if (Array.isArray(value) && value.length > 0) {
                                            return (
                                                <div key={uniqueKey}>
                                                    <Typography variant="h4">{key.replace(/_/g, " ")}</Typography>
                                                    <ImageGallery images={value} maxHeight={50} />
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>

                    {/* Site Audit Images */}
                    {siteAuditForm.images && siteAuditForm.images.length > 0 && (
                        <div>
                            <Typography>Images</Typography>
                            {siteAuditForm.images.map((image, index) => (
                                <img key={`site-image-${index}`} src={image} alt={`Site Image ${index}`} />
                            ))}
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};
