//removing a machine ux and post to server
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext";
import { req } from "../../../utils";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Snackbar,
} from "@mui/material";

export function RemoveMachine({ job, defaultMachine }) {
  const { machines, updateProperty, venueLocations, appendJobLog } =
    useDataContext();
  const { service_call_id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [machineOut, setMachineOut] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  useEffect(() => {
    // Filter warehouses (venues with "WH" and "BottleCycler" in the name)
    try {
      if (!Array.isArray(venueLocations)) return;
      const filteredWarehouses = venueLocations.filter(
        (venue) =>
          String(venue.is_warehouse) === "1"
      );
      // Remove duplicates based on venue_name
      const uniqueWarehouses = filteredWarehouses.reduce((acc, current) => {
        const x = acc.find((item) => item.venue_name === current.venue_name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      setWarehouses(uniqueWarehouses);

      // Set default machine if provided
      if (defaultMachine) {
        const defaultMachineObj = machines.find(
          (m) => String(m.machine_id_pk) === String(defaultMachine)
        );
        if (defaultMachineObj) {
          setMachineOut(defaultMachineObj);
        }
      }
    } catch (error) {
      console.error("Error filtering warehouses:", error);
    }
  }, [venueLocations, machines, defaultMachine]);

  // Filter machines for the current venue
  const filteredMachines = machines.filter(
    (machine) => String(machine.venue_id) === String(job.service_venue_id)
  );
  console.log("machines filtered for removal", filteredMachines);
  const handleSubmit = async () => {
    if (!machineOut || !selectedWarehouse) {
      setSnackbarMessage("Please select a machine and warehouse");
      setSnackbarOpen(true);
      return;
    }

    setIsSubmitting(true);

    const removalTime = new Date().toISOString();

    const data = {
      service_call_id: service_call_id,
      from_venue_id: job.service_venue_id, // Add this line
      to_venue_id: selectedWarehouse.venue_id_fk,
      machine_id: machineOut.machine_id_pk,
      swap_type: "removal",
      time: removalTime,
    };

    // Update local context
    updateProperty(
      "machine",
      machineOut.machine_id_pk,
      "venue_id",
      selectedWarehouse.venue_id_fk
    );
    //unset the selected machine for the autocomplete
    setMachineOut(null);
    // Remove machine from job's machine_ids_array
    const updatedMachineIds = job.machine_ids_array.filter(
      (id) => id !== machineOut.machine_id_pk
    );
    updateProperty(
      "job",
      service_call_id,
      "machine_ids_array",
      updatedMachineIds
    );

    // Add to call history
    const logEntry = {
      time: Math.floor(Date.now() / 1000),
      event: "machine_removed",
      account: parseInt(localStorage.getItem("_bc_tech_user_id")),
      details: {
        machine_id: machineOut.machine_id_pk,
        from_venue_id: job.service_venue_id,
        to_venue_id: selectedWarehouse.venue_id_fk,
        removal_time: removalTime,
      },
      everything: { POST: data },
    };
    appendJobLog(service_call_id, logEntry);

    // Close the dialog immediately
    setOpen(false);
    setSnackbarMessage("Machine removal in progress...");
    setSnackbarOpen(true);

    try {
      // Post to server
      const response = await req("POST", "remove_machine", data);
      console.log(response);
      setSnackbarMessage("Machine removed successfully!");
    } catch (error) {
      console.error("Error removing machine:", error);
      setSnackbarMessage("Error removing machine. Please try again.");
    } finally {
      setIsSubmitting(false);
      setSnackbarOpen(true);
    }
  };
  const handleButtonClick = () => {
    if (job.status === "scheduled" || job.status === "NOT STARTED") {
      alert("Please start the job before installing a machine.");
    } else {
      setOpen(true);
    }
  };
  return (
    <>
      <Button color="persiangreen" onClick={handleButtonClick}>
        Remove machine
      </Button>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={() => setOpen(false)}
        aria-labelledby="remove-machine-dialog-title"
        aria-describedby="remove-machine-dialog-description"
      >
        <DialogTitle id="remove-machine-dialog-title">
          REMOVE Machine
        </DialogTitle>
        <DialogContent>
          <div
            id="remove-machine-dialog-description"
            style={{ marginBottom: "1rem" }}
          >
            Select a machine to remove and a warehouse to move it to.
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="machine_out"
                options={filteredMachines}
                getOptionLabel={(option) =>
                  `${option.serial_id} (${option.machine_type})`
                }
                value={machineOut}
                onChange={(event, value) => setMachineOut(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Machine to remove"
                    variant="outlined"
                  />
                )}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="warehouse"
                options={warehouses}
                getOptionLabel={(option) => option.venue_name}
                value={selectedWarehouse}
                onChange={(event, value) => setSelectedWarehouse(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Warehouse" variant="outlined" />
                )}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="persiangreen"
            style={{ width: "25%" }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="persiangreen"
            style={{ width: "75%" }}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "var(--bc-persiangreen)",
            color: "#fff",
          },
        }}
      />
    </>
  );
}
