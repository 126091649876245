import { Container } from "@mui/material";
import { WarningAmberRounded } from '@mui/icons-material';

export default function OfflineBar() {

    return (<>
        <div className="offline-bar">
            <Container maxWidth="lg">
                <div className="message-bar__content">
                    <div className="message-bar__icon">
                        <WarningAmberRounded style={{ fontSize: "2rem", lineHeight: "2rem" }} />
                    </div>
                    <div className="message-bar__text">
                        You are currently offline. Any changes you make will be saved locally.
                    </div>
                    <div className="message-bar__actions"></div>
                </div>
            </Container>
        </div>
    </>);
}