import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Snackbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDataContext } from "../../../contexts/DataContext";
import { req } from "../../../utils";

export function InstallMachine({ job }) {
  const {
    machines,
    venueLocations,
    setVenueLocations,
    batchUpdate,
    appendJobLog,
  } = useDataContext();
  const { service_call_id } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [machineIn, setMachineIn] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [newLocationName, setNewLocationName] = useState("");
  const [showNewLocationInput, setShowNewLocationInput] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // Filter locations for the current venue
  const filteredLocations = venueLocations.filter(
    (location) => String(location.venue_id_fk) === String(job.service_venue_id)
  );

  // Filter machines for installation (not in the current venue)
  const filteredMachines = machines.filter(
    (machine) => String(machine.venue_id) !== String(job.service_venue_id)
  );

  const handleButtonClick = () => {
    if (job.status === "scheduled" || job.status === "NOT STARTED") {
      alert("Please start the job before installing a machine.");
    } else {
      setOpen(true);
    }
  };

  const handleSubmit = async () => {
    // Validation
    if (!machineIn) {
      setSnackbarMessage("Please select a machine");
      setSnackbarOpen(true);
      return;
    }

    if (!selectedLocation && !showNewLocationInput) {
      setSnackbarMessage(
        "Please select a location or choose to add a new one"
      );
      setSnackbarOpen(true);
      return;
    }

    if (showNewLocationInput && !newLocationName.trim()) {
      setSnackbarMessage("Please enter a name for the new location");
      setSnackbarOpen(true);
      return;
    }

    setIsSubmitting(true);

    const installationTime = new Date().toISOString();
    let installLocationId = null;

    let tempLocationId = null;
    if (showNewLocationInput) {
      // Assign a unique negative ID for the temporary location
      tempLocationId = -Date.now();
      installLocationId = tempLocationId;

      const newLocation = {
        location_id: tempLocationId,
        location: newLocationName,
        venue_id_fk: job.service_venue_id,
      };

      // Optimistically add the temporary location to venueLocations
      setVenueLocations((prev) => [...prev, newLocation]);
    } else if (selectedLocation) {
      installLocationId = selectedLocation.location_id;
    }

    // Prepare installation data
    const data = {
      service_call_id: service_call_id,
      installed_machine_id: machineIn.machine_id_pk,
      venue_id: job.service_venue_id,
      install_location_id: showNewLocationInput ? null : installLocationId,
      new_location_name: showNewLocationInput ? newLocationName : null,
      operation_type: "install",
      time: installationTime,
    };

    // Prepare updates for batch processing
    const updates = [];

    // Update machine_ids_array optimistically
    const currentMachineIds = Array.isArray(job.machine_ids_array)
      ? job.machine_ids_array
      : [];
    const updatedMachineIds = [...currentMachineIds, machineIn.machine_id_pk];
    updates.push({
      objectType: "job",
      id: service_call_id,
      property: "machine_ids_array",
      value: updatedMachineIds,
    });

    // Add to call history
    const logEntry = {
      time: Math.floor(Date.now() / 1000),
      event: "machine_installed",
      account: parseInt(localStorage.getItem("_bc_tech_user_id")),
      details: {
        machine_id: machineIn.machine_id_pk,
        venue_id: job.service_venue_id,
        location_id: showNewLocationInput
          ? 0
          : selectedLocation.location_id,
        installation_time: installationTime,
      },
      everything: { POST: data },
    };
    appendJobLog(service_call_id, logEntry);

    // Update machine properties optimistically
    updates.push(
      {
        objectType: "machine",
        id: machineIn.machine_id_pk,
        property: "venue_id",
        value: job.service_venue_id,
      },
      {
        objectType: "machine",
        id: machineIn.machine_id_pk,
        property: "venue_id_fk", // redundant property
        value: job.service_venue_id,
      },
      {
        objectType: "machine",
        id: machineIn.machine_id_pk,
        property: "venue_location_id_fk",
        value: installLocationId,
      }
    );

    // Apply all updates in a single batch
    batchUpdate(updates);

    // Clear the selected machine
    setMachineIn(null);

    // Close the dialog and show progress snackbar
    setOpen(false);
    setSnackbarMessage("Machine installation in progress...");
    setSnackbarOpen(true);

    try {
      // Post to server
      const response = await req("POST", "install_machine", data);
      console.log("install_machine response:", response);
      setSnackbarMessage("Machine installed successfully!");

      const newLocationId = response.new_location_id;

      if (showNewLocationInput && newLocationId) {
        const newVenueLocation = {
          location_id: newLocationId,
          location: newLocationName,
          venue_id_fk: job.service_venue_id,
          venue_name: job.service_venue_name,
        };

        // Replace the temporary location with the actual one
        setVenueLocations((prev) =>
          prev.map((loc) =>
            loc.location_id === tempLocationId ? newVenueLocation : loc
          )
        );

        console.log("New venue location added:", newVenueLocation);

        // Update machine properties with the actual location ID
        batchUpdate([
          {
            objectType: "machine",
            id: machineIn.machine_id_pk,
            property: "venue_location_id_fk",
            value: newLocationId,
          },
          {
            objectType: "machine",
            id: machineIn.machine_id_pk,
            property: "location_id",
            value: newLocationName,
          },
        ]);
      }

      // Update the job's machine_ids_array
      batchUpdate([
        {
          objectType: "job",
          id: service_call_id,
          property: "machine_ids_array",
          value: updatedMachineIds,
        },
      ]);

      console.log("Job's machine_ids_array updated:", updatedMachineIds);
    } catch (error) {
      console.error("Error installing machine:", error);
      setSnackbarMessage("Error installing machine. Please try again.");

      // Since req() has a retry mechanism, you can choose to handle persistent failures minimally.
      // For instance, you might want to notify the user without reverting optimistic updates.
      // If you still want to revert, uncomment the following lines:

      /*
      // Revert the machine_ids_array update
      batchUpdate([
        {
          objectType: "job",
          id: service_call_id,
          property: "machine_ids_array",
          value: currentMachineIds,
        },
        {
          objectType: "machine",
          id: machineIn?.machine_id_pk,
          property: "venue_id",
          value: null,
        },
        {
          objectType: "machine",
          id: machineIn?.machine_id_pk,
          property: "venue_id_fk",
          value: null,
        },
        {
          objectType: "machine",
          id: machineIn?.machine_id_pk,
          property: "venue_location_id_fk",
          value: null,
        },
      ]);

      // Optionally remove the temporary location if it was added
      if (showNewLocationInput && tempLocationId !== null) {
        setVenueLocations((prev) =>
          prev.filter((loc) => loc.location_id !== tempLocationId)
        );
      }
      */
    } finally {
      setIsSubmitting(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Button
        color="persiangreen"
        onClick={handleButtonClick}
        variant="outlined"
      >
        Install machine
      </Button>
      <Dialog
        open={open}
        fullWidth
        onClose={() => setOpen(false)}
        aria-labelledby="install-machine-dialog-title"
        aria-describedby="install-machine-dialog-description"
      >
        <DialogTitle id="install-machine-dialog-title">
          INSTALL Machine
        </DialogTitle>
        <DialogContent>
          <div
            id="install-machine-dialog-description"
            style={{ marginBottom: "1rem" }}
          >
            Select a machine and location to install.
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="machine_in"
                options={filteredMachines}
                getOptionLabel={(option) =>
                  `${option.serial_id} (${option.machine_type})`
                }
                onChange={(event, value) => setMachineIn(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Machine to install"
                    variant="outlined"
                  />
                )}
                fullWidth
                value={machineIn}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="location_id"
                options={[
                  ...filteredLocations,
                  { location_id: -1, location: "Add new location" },
                ]}
                getOptionLabel={(option) => option.location || ""}
                value={selectedLocation}
                onChange={(event, value) => {
                  if (value && value.location === "Add new location") {
                    setShowNewLocationInput(true);
                    setSelectedLocation(null);
                  } else {
                    setShowNewLocationInput(false);
                    setSelectedLocation(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    variant="outlined"
                  />
                )}
                fullWidth
              />
            </Grid>
            {showNewLocationInput && (
              <Grid item xs={12}>
                <TextField
                  label="New Location Name"
                  variant="outlined"
                  fullWidth
                  value={newLocationName}
                  onChange={(e) => setNewLocationName(e.target.value)}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="persiangreen"
            onClick={() => setOpen(false)}
            disabled={isSubmitting}
            style={{ width: "25%" }}
          >
            Cancel
          </Button>
          <Button
            color="persiangreen"
            onClick={handleSubmit}
            disabled={isSubmitting}
            style={{ width: "75%" }}
            variant="outlined"
          >
            {isSubmitting ? "Installing..." : "Install"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "var(--bc-persiangreen)",
            color: "#fff",
          },
        }}
      />
    </>
  );
}
