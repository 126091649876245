import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useDataContext } from "../../contexts/DataContext";
import ProtectedImageGallery from "../images/ProtectedImageGallery";

export const DisplayCompletedJob = ({ job }) => {
  const [open, setOpen] = useState(false);
  const { machines } = useDataContext();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!job?.status?.toUpperCase().includes("COMPLETED") || !job?.new_assessments) {
    return null;
  }

  const get_machine_serial = (machine_id) => {
    const machine = machines.find(
      (machine) => String(machine.machine_id_pk) === String(machine_id)
    );
    return machine ? machine.serial_id : "";
  };

  // Convert timestamp to local date string
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    return date.toLocaleDateString("en-AU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  // Generate stars for the rating
  const generateStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? "½" : "";
    return "★".repeat(fullStars) + halfStar;
  };
  const formatTf=(tf)=>{
    console.log("formatTf",tf);
    if(tf)
      return 'Yes';
    else
      return 'No';
    return tf;
  }

  const formatGallery = (images) => {
    if (!images) return null;
    try {
      let parsedImages
      if(typeof images === 'string')
        parsedImages = JSON.parse(images);
      else
        parsedImages = images;
      return <ProtectedImageGallery images={parsedImages} />;
    } catch (e) {
      console.error("Error parsing images: ", e);
    }
  };

  const assessments = job?.new_assessments || [];
  const show=(message)=>{
    console.log(message);
  }
  return (
    <div>
      <Button
        variant="text"
        fullWidth
        color="persiangreen"
        onClick={handleOpen}
      >
        Show Completed Assessment
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Completed Assessments</DialogTitle>
        <DialogContent>
          {assessments.map((assessment, index) => (
            
            <DialogContentText key={index}>
              { show(assessment) }
              <strong>Completion:</strong> {formatDate(parseInt(assessment.time_recorded))}
              <br />
              <strong>Machine ID:</strong> {get_machine_serial(assessment.machine_id)}
              <br />
              <strong>Machine Condition:</strong>{" "}
              {generateStars(parseFloat(assessment.machine_rating))} (
              {assessment.machine_rating})<br />
              {assessment?.details?.machine_tag && (
                <>
                  <strong>Machine Tag:</strong> {assessment?.details?.machine_tag}
                  <br />
                  <strong>Tag Date:</strong>{" "}
                  {formatDate(new Date(assessment?.details?.machine_tagged_date).getTime() / 1000)}
                  <br />
                  <strong>Tag Due Date:</strong>{" "}
                  {formatDate(new Date(assessment?.details?.machine_tag_due_date).getTime() / 1000)}
                  <br />
                </>
              )}
              <strong>Completion cleaned:</strong> {formatTf(assessment?.details?.machine_cleaned)}
              <br />
              <strong>Completion inspected:</strong> {formatTf(assessment?.details?.machine_inspection_completed)}
              <br />
              <strong>Completion Notes:</strong> {assessment?.details?.completion_notes}
              <br />
              {formatGallery(assessment?.details?.images)}
              <hr />
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DisplayCompletedJob;
