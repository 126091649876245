import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Snackbar, CircularProgress, MenuItem } from '@mui/material/';
import SendIcon from '@mui/icons-material/Send';
import { req, log } from '../../utils';
import { useDataContext } from '../../contexts/DataContext';

const BinDropAndRemoval = ({ job }) => {
    const [qtyOfBins, setQtyOfBins] = useState(0);
    const { appendJobLog } = useDataContext();
    const [submitted, setSubmitted] = useState(false);
    const [buttonAction, setButtonAction] = useState('Added');
    const [binsCollected, setBinsCollected] = useState([]);
    const [defaultBinsQty, setDefaultBinsQty] = useState(0);
    const [snack, setSnack] = useState({
        open: false,
        message: '',
    });

    const handleQtyChange = (e) => {
        setQtyOfBins(parseInt(e.target.value, 10));
    };

    // Updated to handle submission with or without geolocation data
    const handleSubmission = (lat = '0', long = '0', accuracy = '0') => {
        try {
            let qty_of_bins = qtyOfBins;
            if (buttonAction === 'Removed') {
                qty_of_bins = qtyOfBins * -1;
            }
            const data = {
                venue_id: job.venue_id,
                service_call_id: job.service_call_id,
                qty_of_bins,
                lat: lat,
                long: long,
                accuracy_in_meters: accuracy,
            };
            setSubmitted(true);
            req('post', 'bins_dropped', data);
            appendJobLog(job.service_call_id, {
                event: "bins_dropped",
                time: Math.floor(Date.now() / 1000),
                details: {
                    qty_of_bins,
                    lat: lat,
                    long: long,
                    accuracy_in_meters: accuracy,
                }
            });
            setSnack({ open: true, message: 'Bins counted' });
            setQtyOfBins(0);
            setDefaultBinsQty(0);
            setSubmitted(false);
        } catch (e) {
            log("error in handleSubmission of binDropandRemoval", e);

        };
    }

    const handleGeolocationSuccess = (position) => {
        const { latitude, longitude, accuracy } = position.coords;
        handleSubmission(String(latitude), String(longitude), String(accuracy));
    };

    const handleGeolocationError = (error) => {
        console.warn(`ERROR(${error.code}): ${error.message}`);
        // Fallback to 0 values for lat and long on error
        handleSubmission();
        setSnack({ open: true, message: 'Error obtaining location. Bins replacement without location data.' });
        logError('Error obtaining location', error);
    };

    const handleSubmit = () => {
        if (qtyOfBins > 0) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError, {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                });
            } else {
                // Directly submit with 0 values if geolocation is not supported
                handleSubmission();
                setSnack({ open: true, message: 'Geolocation is not supported by this browser. Bins collected without location data.' });
            }
        } else {
            setSnack({ open: true, message: 'Please add quantity first!' });
        }
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({ ...snack, open: false });
    };

    const logError = (subject, details) => {
        // Add your logging mechanism here
        log(subject, details);
    };

    useEffect(() => {
        if (job?.log && Array.isArray(job.log)) {
            setBinsCollected(job.log.filter((log) => log.event === 'bins_dropped'));
        }
    }, [job]);

    return (<>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={8}>
                <TextField
                    autoFocus
                    id="qtyBins"
                    name="qtyBins"
                    type="number"
                    minval={0}
                    placeholder={`Nbr bins to be ${buttonAction.toLowerCase()}`}
                    label={`Nbr bins to be ${buttonAction.toLowerCase()}`}
                    fullWidth
                    color="persiangreen"
                    disabled={submitted}
                    onChange={handleQtyChange}
                    defaultValue={defaultBinsQty}
                    InputProps={{
                        sx: {
                            height: '56px',
                            '& .MuiInputBase-input': {
                                textAlign: 'center',
                            },
                        }
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    select
                    id="binStatus"
                    name="binStatus"
                    label="Action"
                    fullWidth
                    color="persiangreen"
                    disabled={submitted}
                    defaultValue="Added"
                    onChange={(e) => setButtonAction(e.target.value)}
                    InputProps={{
                        sx: {
                            height: '56px',
                            '& .MuiInputBase-input': {
                                textAlign: 'center',
                            },
                        }
                    }}
                >
                    <MenuItem value="Added">Added</MenuItem>
                    <MenuItem value="Removed">Removed</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12}>

                <Button
                    variant="contained"
                    className={submitted ? 'btn-submit btn-persiangreen-hover' : 'btn-submit'}
                    color="persiangreen"
                    disabled={submitted}
                    onClick={handleSubmit}
                    fullWidth
                    endIcon={submitted ? <CircularProgress size={16} style={{ marginLeft: '.5rem', marginTop: "-4px" }} /> : <SendIcon size={16} style={{ marginLeft: '.5rem', marginTop: "-4px" }} />}
                >
                    {submitted ? `Recording bins ${buttonAction.toLowerCase()}` : `Record bins ${buttonAction.toLowerCase()}`}
                </Button>
            </Grid>
        </Grid>
        <div style={{ textAlign: 'left', display: 'block', marginTop: "1rem" }}>
            {binsCollected.length === 0 ? null :
                (<div className='feedback-sent-title'>Bins dropped or removed</div>)}
            {binsCollected.map((item) => {
                let action = "dropped off";
                let nbr = item.details.qty_of_bins;
                if (item.details.qty_of_bins < 0) {
                    action = "removed";
                    nbr = nbr * -1;//absolute value
                }
                return (
                    <div className={"feedback"} key={item.time}>
                        <div className='message'>{nbr} {nbr > 1 ? 'bins' : 'bin'} {action}</div>
                        <div className='date'>{new Date(item.time * 1000).toLocaleString()}</div>
                    </div>
                )
            })}
        </div>

        <Snackbar
            open={snack.open}
            autoHideDuration={3000}
            onClose={handleCloseSnack}
            message={snack.message}
        />
    </>
    );
};
export default BinDropAndRemoval;
