import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useDataContext } from "../../contexts/DataContext";
import { useNavigate } from "react-router-dom";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import '../Reschedule';
import { req } from "../../utils";
import Reschedule from "../Reschedule";
const ListViewItem = ({ call }) => {
  let navigate = useNavigate();
  const { jobs, machines, isAdmin } = useDataContext();
  const handleClick = (url) => {
    navigate(url);
  };
  const cancelPm = (call) => {
    //console.log("Cancel PM", call);
    if (
      window.confirm(
        "Are you sure you want to cancel this preventative maintenance?"
      )
    ) {
      //Cancel PM
      req("POST", "cancel_pm", {
        service_call_id: call.service_call_id,
        venue_id: call.service_venue_id,
      }).then((res) => {
        if (res) {
          alert("Preventative maintenance has been canceled.");
          window.location.reload();
        }
      });
    }
  };
  const buildAppleMapsLink = (latitude, longitude) => {
    return `https://maps.apple.com/?daddr=${latitude},${longitude}`;
  };
 
  const getCallLocation = (call) => {
    let job = jobs.find(
      (j) => String(j?.service_call_id) === String(call.service_call_id)
    );
    let jobMachines = job?.machine_ids_array;
    if (Array.isArray(jobMachines) && jobMachines.length > 0) {
      let jobMachine = machines[0];
      jobMachine = machines.find(
        (m) => String(m?.machine_id_pk) === String(jobMachines[0])
      );
      if (jobMachine) {
        return (
          <div style={{ fontWeight: "bolder" }}>
            <GpsFixedIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              color="persiangreen"
            >
              {" "}
            </GpsFixedIcon>
            {jobMachine.location}
          </div>
        );
      }
    }
    return <>No location found</>;
  };
  const getMachineSerialText = () => {
    let returnValue = "";
    //if machine ids are in the call
    if (call.machine_ids_array && call.machine_ids_array.length > 0) {
      returnValue =
        call.machine_ids_array.length > 1 ? "Machines: " : "Machine: ";
      for (let i = 0; i < call.machine_ids_array.length; i++) {
        let machine = machines.find(
          (m) => String(m?.machine_id_pk) === String(call.machine_ids_array[i])
        );
        if (machine) {
          returnValue += machine.serial_id;
          if (i < call.machine_ids_array.length - 1) {
            returnValue += ", ";
          }
        }
      }
      return returnValue;
    }
    return null;
  };
  let assinged_user = call?.assigned_to_name;
  if (!assinged_user) assinged_user = "#" + call?.assigned_to;
  if(String(call?.service_call_id) ==="93"){
    console.log("DEBUGGING ", call?.status?.toLowerCase(),(call?.status?.toLowerCase() !== "completed" || call?.status?.toLowerCase() !=='rescheduled'),call?.status?.toLowerCase() !== "completed",call?.status?.toLowerCase() !=='rescheduled');
  }
  return (
    <div
      key={call.tech_run_stop_id}
      onClick={(e) => {
        handleClick(`/call/${call.service_call_id}`);
        e.stopPropagation();
      }}
      className={`schedule-list--row highlight-${call?.highlight}`}
    >
        <div className="col-time flex">
          {call.time ? call.time.replace(/:00$/, "") : "No Time"}
          <span style={{ margin: "0 .25rem" }}>-</span>
          <span>
            {call.service_call_type ?? "No call type"}
            <br />
            Status:{" "}
            {call.status ? (
            <span style={{ textTransform: "uppercase" }}>{call.status}</span>
            ) : (
            "No call status"
            )}
          </span>
        </div>
        <div className="col-type">
          {call.service_call_type ?? "No call type"}
          <div>
            Status:{" "}
            {call.status ? (
            <span style={{ textTransform: "uppercase" }}>{call.status}</span>
            ) : (
            "No call status"
            )}
          </div>
        </div>
        <div className="col-venue">
          {call.venue_name ?? "No Venue"}
          <br />
          <div className="text-muted font-smaller">
            {call.address ? (
            <>
              {call.address}
              <div>
                {call.suburb}, {call.postal_code}
              </div>
              {getCallLocation(call)}
            </>
            ) : (
            "No address"
            )}
            <div>
            <Button
              size="small"
              variant="text"
              style={{ paddingLeft: "0px", marginLeft: "0px" }}
              color="persiangreen"
              component="a"
              href={buildAppleMapsLink(call?.latitude, call?.longitude)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <AssistantDirectionIcon
                style={{ fontSize: "16px", marginRight: "5px" }}
              />{" "}
              Apple Maps
            </Button>
            </div>
          </div>
          <div>{getMachineSerialText()}</div>
        </div>
        <div className="col-contact">
          {call?.contact_name}
          {call.contact_phone && (
            <div>
            <Link
              to={`tel:${call.contact_phone}`}
              onClick={(e) => e.stopPropagation()}
            >
              {call.contact_phone}
            </Link>
            </div>
          )}
          {call.contact_email && (
            <div>
            {call.contact_email.includes(",") ? (
              call.contact_email.split(",").map((email) => (
                <div key={email}>
                <Link
                  target="_blank"
                  to={`mailto:${email}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {email}
                </Link>
                </div>
              ))
            ) : (
              <Link
                target="_blank"
                to={`mailto:${call.contact_email}`}
                onClick={(e) => e.stopPropagation()}
              >
                {call.contact_email}
              </Link>
            )}
            </div>
          )}
        </div>
        <div className="col-misc">
          {(call.origin === "Preventative Maintenance Queue"  && (call?.status?.toLowerCase() !== "completed" && call?.status?.toLowerCase() !=='reschedule')) && (
            <div>
            <Button
              onClick={(e) => {
                cancelPm(call);
                e.stopPropagation();
              }}
              fullWidth
              variant="outlined"
            >
              Cancel PM
            </Button>
          </div>
        )}
        {isAdmin && <div>Assigned To: {assinged_user}</div>}
        {call.time_restriction && (
          <div>
            <AccessTimeFilledIcon color="persiangreen" sx={{ fontSize: 16 }} />{" "}
            Restriction: {call.time_restriction}
          </div>
        )}
        {(call?.status?.toLowerCase() !== "completed" && call?.status?.toLowerCase() !=='reschedule') && (
            <Reschedule call={call} />
        )}
      </div>
    </div>
  );
};

export default ListViewItem;
