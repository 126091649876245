import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    useTheme,
    useMediaQuery,
    Autocomplete,
    Chip,
    IconButton,
    InputAdornment,
} from "@mui/material";
import SortAscendingIcon from "@mui/icons-material/ArrowUpward";
import SortDescendingIcon from "@mui/icons-material/ArrowDownward";
import ClearIcon from '@mui/icons-material/Clear';
import { log } from "../../utils";
import Note from "./Note";

import { req } from "../../utils";
import { useDataContext } from "../../contexts/DataContext";

export const Notes = ({ job }) => {
    const { updateProperty, venueLocations, machines } = useDataContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notes, setNotes] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortOrder, setSortOrder] = useState("desc"); // 'asc' for ascending, 'desc' for descending

    const [newNote, setNewNote] = useState({
        tags: [],
        message: "",
    });

    const handleSortToggle = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const sortTags = (tag) => {
        try {
            //const filteredNotes = notes.filter(note => note.tags.some(t => t.title === tag));
            //setNotes(filteredNotes);
        } catch (error) {
            log("Sort Tags Error", error);
        }
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleSearchNotes = (query) => {
        setSearchQuery(query);
    };

    const handleClearSearch = () => {
        try {
            setSearchQuery('');
            // Optionally refocus the search input after clearing
            document.getElementById('search-notes-input').focus();
        } catch (error) {
            log("Clear Search Error", error);
        }
    };

    const handleDeleteNote = (note_id) => {
        try {
            req("POST", "delete_note", { note_id });
            //delete from local context
            let newNotes = notes.filter(
                (note) => String(note.note_id) !== String(note_id)
            );
            updateProperty("job", job.service_call_id, "call_notes", newNotes);
        } catch (error) {
            log("Delete Note Error", error);
        }
    };

    const handleAddNote = async () => {
        setIsSubmitting(true);
        console.log("New Note:", newNote);
        //show loading
        let data = {
            ...newNote,
            tags: JSON.stringify(newNote.tags), // Serialize the tags array
            service_call_id: job.service_call_id,
        };

        try {
            let new_note = await req("POST", "add_note", data);
            setIsSubmitting(false);
            if (!new_note) {
                alert("Error adding note");
                return;
            }
            //console.log("new_notes:", new_note);
            // Assuming new_note is an object
            let newNotes = [...notes, new_note];
            updateProperty("job", job.service_call_id, "call_notes", newNotes);
            setOpenDialog(false);
        } catch (error) {
            log("Add Note Error", error);
        }
    };

    useEffect(() => {
        try {
            let formattedNotes = job?.call_notes || [];
            //sort by created_time newest first
            formattedNotes.sort((a, b) => {
                if (sortOrder === "asc") {
                    return a.created_time - b.created_time;
                } else {
                    return b.created_time - a.created_time;
                }
            });
            // Apply search query filter
            const filteredNotes = formattedNotes.filter((note) =>
                note.note.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setNotes(filteredNotes);
        } catch (error) {
            log("Use Effect Error", error);
        }
    }, [job, searchQuery, sortOrder]);

    const getVenueName = (venue_id) => {
        try {
            return (
                venueLocations.find(
                    (venue) => String(venue.venue_id_fk) === String(venue_id)
                )?.venue_name || "Venue"
            );
        } catch (error) {
            log("Get Venue Name Error", error);
            return "Venue";
        }
    };

    const getMachineSerial = (machine_id) => {
        try {
            return (
                machines.find(
                    (machine) => String(machine.machine_id_pk) === String(machine_id)
                )?.serial_id || ""
            );
        } catch (error) {
            log("Get Machine Serial Error", error);
            return "";
        }
    };

    useEffect(() => {
        try {
            // Create tags after the job has loaded
            const tags = [
                {
                    title: `Venue: ${getVenueName(job.venue_id)}`,
                    foreign_id: job.venue_id,
                    foreign_subject: "venue",
                },
            ];
            //loop through machines and add to tags
            if (job?.machine_ids_array && Array.isArray(job.machine_ids_array)) {
                job.machine_ids_array?.forEach((machine_id) => {
                    tags.push({
                        title: `Machine ${getMachineSerial(machine_id)}`,
                        foreign_id: machine_id,
                        foreign_subject: "machine",
                    });
                });
            }
            setNewNote((prevNote) => ({ ...prevNote, tags: tags }));
        } catch (error) {
            log("Set New Note Error", error);
        }
    }, [job]);

    return (
        <>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs>
                    <TextField
                        color="persiangreen"
                        variant="standard"
                        label="Search Notes"
                        value={searchQuery}
                        onChange={(e) => handleSearchNotes(e.target.value)}
                        fullWidth
                        id="search-notes-input"
                        InputProps={{
                            endAdornment: searchQuery && (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clear search"
                                        onClick={handleClearSearch}
                                        edge="end"
                                        size="large"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ marginRight: '8px' }} // Adjust spacing as needed
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        color="persiangreen"
                        onClick={handleSortToggle}
                        size="large"
                    >
                        {sortOrder === "asc" ? (
                            <SortAscendingIcon />
                        ) : (
                            <SortDescendingIcon />
                        )}
                    </IconButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                    <Button
                        variant="contained"
                        color="persiangreen"
                        onClick={() => setOpenDialog(true)}
                        fullWidth
                        sx={{ marginTop: { xs: "1rem", sm: "0" } }} // Adjust the top margin for mobile view
                    >
                        Add note
                    </Button>
                </Grid>
            </Grid>
            <Grid sx={{ marginTop: "1rem" }} item xs={12}>
                {notes?.map((note) => (
                    <Note
                        sortTags={sortTags}
                        handleDeleteNote={handleDeleteNote}
                        getVenueName={getVenueName}
                        getMachineSerial={getMachineSerial}
                        key={note.note_id}
                        note={note}
                        onRemoveNote={handleDeleteNote}
                    />
                ))}
            </Grid>
            <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Add Note</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        color="persiangreen"
                        margin="dense"
                        label="Note"
                        multiline
                        disabled={isSubmitting}
                        rows={4}
                        value={newNote.message}
                        onChange={(e) =>
                            setNewNote({ ...newNote, message: e.target.value })
                        }
                    />
                    <Autocomplete
                        multiple
                        color="persiangreen"
                        options={[]} // This should be your list of all available tags options
                        getOptionLabel={(option) => option.title}
                        value={newNote.tags || []}
                        onChange={(event, newValue) => {
                            setNewNote({ ...newNote, tags: newValue });
                        }}
                        renderInput={(params) => (
                            <TextField {...params} margin="dense" label="Tags" />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    color="persiangreen"
                                    variant="outlined"
                                    label={option.title}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="persiangreen"
                        disabled={isSubmitting}
                        onClick={() => {
                            setOpenDialog(false);

                            setNewNote({
                                tags: [{ title: "Service Call" }],
                                message: "",
                            });
                        }}
                        style={{ width: "25%" }}
                    >
                        {isSubmitting ? "Submitting..." : "Cancel"}
                    </Button>
                    <Button
                        onClick={handleAddNote}
                        color="persiangreen"
                        style={{ width: "75%", textAlign: "right" }}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Submitting..." : "Add Note"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Notes;
