import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import { log } from "../../utils";

const MapView = ({ dailyJobs }) => {
  const testing = process.env.REACT_APP_ENV === "test"; //used to use geo location vs first stop 
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [fetchedDirections, setFetchedDirections] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [directions, setDirections] = useState(null);
  const [center, setCenter] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [waypoints, setWaypoints] = useState([]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });



  const calculateCenter = (positions) => {
    if (positions.length === 0) {
      return { lat: -37.8317127, lng: 144.9186861 }; // Default center
    }
    const latSum = positions.reduce((sum, pos) => sum + pos.lat, 0);
    const lngSum = positions.reduce((sum, pos) => sum + pos.lng, 0);
    return { lat: latSum / positions.length, lng: lngSum / positions.length };
  };

  // Fetch directions once everything is set up
  const fetchDirections = () => {
    const directionsService = new window.google.maps.DirectionsService();
    console.log("fetching directions",origin, destination, waypoints);
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`Error fetching directions: ${status}`);
        }
      }
    );
  };

  useEffect(() => {
    if (!dailyJobs.length || !isLoaded || fetchedDirections) return;

    let points = dailyJobs.map((job) => ({
      lat: parseFloat(job.latitude),
      lng: parseFloat(job.longitude),
    }));

    // Set origin and destination based on the first and last job
    if (testing) {
      setOrigin(points[0]); //todo set this to mobile location
    } else {
      try {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const mobileLatitude = position.coords.latitude;
            const mobileLongitude = position.coords.longitude;
            setOrigin({ lat: mobileLatitude, lng: mobileLongitude });
          });
        }
      } catch (e) {
        log("Error getting location", e);
        setOrigin(points[0]); //todo set this to mobile location
      }
    }
    setDestination(points[points.length - 1]);
    setCenter(calculateCenter(points));
    // Create waypoints from the remaining points
    setWaypoints(
      points.slice(1, -1).map((point) => ({ location: point, stopover: true }))
    );

    if (isLoaded && origin && destination && waypoints.length) {
      setFetchedDirections(true);
      fetchDirections();
      generateMarkers(dailyJobs);
    }
  }, [isLoaded, origin, destination, waypoints]);

  const mapContainerStyle = {
    height: "calc(100% - 30px)",
    width: "100%",
  };

  const generateMarkers = (jobs) => {
    try {
      let markersIcons = jobs.map((job, index) => {
        const position = {
          lat: Number(job.latitude),
          lng: Number(job.longitude),
        };
        let title = `${job?.venue_name} - ${job?.bc_call_code}`;
        const markerIcon = {
          path: window.google.maps.SymbolPath.CIRCLE, // or any SVG path
          fillColor: job?.highlight,
          fillOpacity: 0.9,
          scale: 12,
          strokeColor: "white",
          strokeWeight: 2,
        };
        return (
          <Marker
            key={index}
            zIndex={1000}
            title={title}
            icon={markerIcon}
            options={{ title: title }}
            position={position}
            onClick={() => setSelectedMarker(job)}
          />
        );
      });
      setMarkers(markersIcons);
    } catch (error) {
      log("Error generating markers", error);
    }
  };

  if (dailyJobs.length < 1) {
    return <div>No jobs available</div>;
  }

  // Only render the map once all the data is ready and API is loaded
  if (isLoaded && center) {
  
    return (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
      >
        {directions && (
          <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />
        )}
        {markers && markers}
        {selectedMarker && (
          <InfoWindow
            position={{
              lat: Number(selectedMarker.latitude),
              lng: Number(selectedMarker.longitude),
            }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div className={`marker_${selectedMarker?.highlight}`}>
              <h2>{`${selectedMarker?.bc_call_code} - ${selectedMarker?.service_call_type}`}</h2>
              <div>{selectedMarker?.venue_name}</div>
              <div>{selectedMarker.time_restriction && "Restriced to: " + selectedMarker.time_restriction}</div>
              <div>{selectedMarker.time}</div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default MapView;
