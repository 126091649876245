import { useEffect, useState } from 'react';
import { useDataContext } from '../../contexts/DataContext';
import { req, log } from '../../utils';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  MenuItem,
  ListSubheader,
  Select,
  TextField,
  Tooltip,
  Snackbar,
} from '@mui/material';
import { Edit } from '@mui/icons-material';

export function EditMachineLocation({ job, machineID, venueID, machineLocationID, machineLocation }) {
  console.log("EditMachineLocation", job, machineID, venueID, machineLocationID, machineLocation);
    const { updateProperty, venueLocations, setVenueLocations, appendJobLog } = useDataContext();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const [location, setLocation] = useState(machineLocation);
  const [selectedValue, setSelectedValue] = useState(machineLocationID);
  const [newLocationName, setNewLocationName] = useState('');
  const [showNewLocationInput, setShowNewLocationInput] = useState(false);

  // Temporary ID counter for new locations
  const [tempIdCounter, setTempIdCounter] = useState(-1);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false); // Reset error state on close
  };

  // Filter locations for the current venue
  const filteredLocations = venueLocations.filter(
    (item) => String(item.venue_id_fk) === String(venueID)
  );

  const locationsOptions = filteredLocations.map((item) => (
    <MenuItem value={item.location_id} key={item.location_id}>
      {item.location}
    </MenuItem>
  ));

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (value === 'ADD_NEW_LOCATION') {
      setShowNewLocationInput(true);
    } else {
      setShowNewLocationInput(false);
    }
  };

  useEffect(() => {
    setLocation(machineLocation);
    setSelectedValue(machineLocationID);
    setShowNewLocationInput(false);
    setNewLocationName('');
  }, [machineLocation, machineLocationID]);

  // Function to create a new location optimistically
  const createNewLocationOptimistically = () => {
    const tempLocationId = tempIdCounter;
    setTempIdCounter((prev) => prev - 1); // Decrement for next temp ID

    const tempLocation = {
      location_id: tempLocationId,
      location: newLocationName,
      venue_id_fk: venueID,
    };

    // Optimistically add the temporary location to venueLocations
    setVenueLocations((prev) => [...prev, tempLocation]);

    return tempLocationId;
  };

  // Function to replace the temporary location with the actual one from the server
  const replaceTemporaryLocation = (tempLocationId, newLocation) => {
    setVenueLocations((prev) =>
      prev.map((loc) =>
        loc.location_id === tempLocationId ? newLocation : loc
      )
    );
  };

  const handleUpdateMachineLocation = async () => {
    if (machineLocationID === selectedValue && !showNewLocationInput) {
      // No change in location
      setOpen(false);
      return;
    }

    setIsSubmitting(true);
    setError(false);

    let finalLocationId = selectedValue;
    let finalLocationName = '';

    // If adding a new location
    if (showNewLocationInput) {
      // Optimistically create a new location with a temporary ID
      finalLocationId = createNewLocationOptimistically();
      finalLocationName = newLocationName;
    } else {
      // Existing location selected
      const existingLocation = venueLocations.find(
        (item) => item.location_id === selectedValue
      );
      finalLocationName = existingLocation ? existingLocation.location : '';
    }

    // Optimistically update the machine's location
    updateProperty("machine", machineID, "machine_location_id_fk", finalLocationId);
    setLocation(finalLocationName);
    setOpen(false);
    setSnackbarMessage("Machine location update in progress...");

    // Prepare installation data
    const postData = {
      machine_id: machineID,
      venue_id: venueID,
      to_location_id: finalLocationId,
      new_venue_location_text: finalLocationName,
      from_location_id: machineLocationID,
      time: new Date().toISOString(),
      service_call_id: job.service_call_id,
    };

    // Add to call history
    const logEntry = {
      time: Math.floor(Date.now() / 1000),
      event: "machine_location_changed",
      account: parseInt(localStorage.getItem("_bc_tech_user_id")),
      details: {
        machine_id: machineID,
        venue_id: venueID,
        from_location_id: machineLocationID,
        to_location_id: finalLocationId,
        location_name: finalLocationName,
        change_time: postData.time,
      },
      everything: { POST: postData },
    };
    appendJobLog(job.service_call_id, logEntry);

    try {
      // Post to server
      const response = await req('POST', 'move_machine_in_venue', postData);
      console.log('POST move_machine_in_venue response:', response);

      // If a new location was added, replace the temporary location with the actual one
      if (showNewLocationInput && response.new_location_id) {
        const newVenueLocation = {
          location_id: response.new_location_id,
          location: newLocationName,
          venue_id_fk: venueID,
          venue_name: job.service_venue_name,
        };

        // Replace the temporary location with the actual one
        replaceTemporaryLocation(finalLocationId, newVenueLocation);

        // Update machine properties with the actual location ID
        updateProperty("machine", machineID, "machine_location_id_fk", newVenueLocation.location_id);
        updateProperty("machine", machineID, "machine_location", newVenueLocation.location);
      }

      setSnackbarMessage("Machine location updated successfully!");
    } catch (error) {
      console.error('Error during change machine location submission', error);
      log('Error during change machine location submission', error);
      setSnackbarMessage("Failed to update machine location. Please try again.");
      setError(true);
      // Note: Since we're assuming success due to the retry mechanism,
      // we're not reverting the optimistic updates here.
      // If desired, you can implement rollback logic as shown below.
    } finally {
      setIsSubmitting(false);
      setSnackbarOpen(true);
    }
  };

  // Snackbar state and handlers
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <>
      <div className="machine-details--wrapper">
        <span className="machine-details--title text-muted">Machine Location:</span>
        <span className="machine-details--text">
          {location === '0' ? 'No location yet' : location}
          <Tooltip title="Change Machine Location">
            <IconButton
              aria-label="Change Machine Location"
              onClick={handleOpen}
              size="small"
              sx={{ marginLeft: '.25rem' }}
            >
              <Edit
                color="persiangreen"
                fontSize="small"
                style={{ marginTop: '-2px' }}
              />
            </IconButton>
          </Tooltip>
        </span>
      </div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-machine-location-dialog-title"
      >
        <DialogTitle id="edit-machine-location-dialog-title">
          <strong>Change Machine Location</strong>
        </DialogTitle>
        <DialogContent>
          <Select
            value={selectedValue}
            onChange={handleChange}
            color="persiangreen"
            style={{ backgroundColor: '#fff' }}
            sx={{ width: '100%' }}
            id="changeLocation_location"
            displayEmpty
          >
            {locationsOptions}
            <ListSubheader>----------</ListSubheader>
            <MenuItem value="ADD_NEW_LOCATION">Add New Location</MenuItem>
          </Select>
          {showNewLocationInput && (
            <TextField
              label="New Location Name"
              variant="outlined"
              color="persiangreen"
              id="changeLocation_new_location"
              style={{ backgroundColor: '#fff' }}
              sx={{ width: '100%', marginTop: '1rem' }}
              value={newLocationName}
              onChange={(e) => setNewLocationName(e.target.value)}
            />
          )}
                  </DialogContent>
         <DialogActions>
            <Button
              color="persiangreen"
              disabled={isSubmitting}
              elevation={0}
              onClick={handleClose}
              variant="outlined"
              style={{ width: "25%" }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="persiangreen"
              disabled={isSubmitting}
              elevation={0}
              onClick={handleUpdateMachineLocation}
              style={{ width: "75%" }}
            >
              {isSubmitting ? (
                <>
                  Moving <CircularProgress size={16} style={{ marginLeft: '.5rem', marginTop: "-4px" }} />
                </>
              ) : (
                'Move Location'
              )}
            </Button>
            </DialogActions>

        {error && (
          <DialogActions>
            <div className="dialog-actions--message" style={{ color: 'red', padding: '0 1rem' }}>
              Failed to Change Machine Location. Please try again or Report A Bug in Give feedback.
            </div>
          </DialogActions>
        )}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <Edit fontSize="small" />
          </IconButton>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "var(--bc-persiangreen)",
            color: "#fff",
          },
        }}
      />
    </>
  );
}
