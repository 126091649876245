import { useDataContext } from '../../../contexts/DataContext';

import OfflineBar from './OfflineBar';
import SyncDataBar from './SyncDataBar';

export default function MessageBar() {

    const { isOnline, syncData } = useDataContext();

    // Wrapper tag for class manipulation
    const wrapper = document.querySelector('.bc-global-wrapper');
    if (wrapper) {
        wrapper.classList.remove('show-message');
    }

    if (wrapper && (!isOnline || syncData)) {
        wrapper.classList.add('show-message');
    }
    
    return (<>
        <div className="bc-page--message">
            {!isOnline && <OfflineBar />}
            {isOnline && syncData && <SyncDataBar />}
        </div>
    </>);
}