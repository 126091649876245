import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useDataContext } from '../contexts/DataContext';
import { JobProgress } from '../components/Jobs/JobProgress';
import JobAccordion from "../components/Jobs/JobAccordian";
import JobHeader from "../components/Jobs/JobHeader";
import { JobDetails } from "../components/Jobs/JobDetails";

import { Button, CircularProgress, Container, ButtonGroup, Grid } from "@mui/material";
import { KeyboardBackspace } from '@mui/icons-material';
import { req, parseJsonProperties, log } from '../utils';
import { DisplayCompletedJob } from "../components/Jobs/DisplayCompletedJob";
import Reschedule from "../components/Reschedule";
import { InstallMachine } from "../components/Jobs/moving_machines/InstallMachine";
import { RemoveMachine } from "../components/Jobs/moving_machines/RemoveMachine";
import { SwapMachine } from "../components/Jobs/moving_machines/SwapMachine";
// Memoizing JobDetails, JobAccordion, and MoveMachine components to prevent unnecessary re-renders
const JobDetailsMemoized = React.memo(JobDetails);
const JobAccordionMemoized = React.memo(JobAccordion);

const JobProgressMemoized = React.memo(JobProgress);
const JobHeaderMemoized = React.memo(JobHeader);
export function Job() {
  const { loading, updateProperty, jobs, setJobs } = useDataContext();
  const { service_call_id } = useParams();
  const [pageError, setPageError] = useState(false);
  const [pageErrorMessage, setPageErrorMessage] = useState('');
  const [jobMissing, setJobMissing] = useState(false);
  const [assessmentsComplete, setAssessmentsComplete] = useState(false);

  // Finding the job in the jobs array or defaulting to false
  const job = useMemo(() => jobs.find(item => String(item.service_call_id) === String(service_call_id)) || false, [jobs, service_call_id]);

  // Computing the defaultMachine based on the job details
  const defaultMachine = useMemo(() => job?.machine_ids_array?.length === 1 ? job.machine_ids_array[0] : null, [job]);

  // Fetch job details if not available in the context
  useEffect(() => {
    if (!job) {
      setJobMissing(true);
      try {
        req('get', 'get_job_with_images', { service_call_id })
          .then(data => {
            if (data) {
              setJobs(prev => [...prev, parseJsonProperties(data)]);
              setJobMissing(false);
            } else {
              setPageError(true);
              setPageErrorMessage('Job not found');
            }
          })
          .catch(error => {
            log("Error fetching job data", error);
            setPageError(true);
            setPageErrorMessage('An error occurred while fetching job details');
          });
      } catch (error) {
        log("Error fetching job data", error);
        setPageError(true);
        setPageErrorMessage('An error occurred while fetching job details');
      }
    } else {
      setPageError(false);
      setPageErrorMessage('');
    }
  }, [service_call_id, job, setJobs]);

  // Update job status
  const handleUpdateJobStatus = async (data) => {
    try {
      await updateProperty('job', service_call_id, 'status', data.status);
      setAssessmentsComplete(false);
    } catch (error) {
      log("Error updating job status", error);
    }
  };

  if (jobMissing) return (
    <main className="bc-page--content">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }} // This ensures the container takes at least the full viewport height
      >
        <Grid item>
          Job missing from memory, loading from server...
        </Grid>
      </Grid>
    </main>
  );
  if (loading) {
    return (
      <main className="bc-page--content">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }} // This ensures the container takes at least the full viewport height
        >
          <Grid item>
            <CircularProgress color="persiangreen" />
          </Grid>
        </Grid>
      </main>
    );
  }

  if (pageError) return <main className="bc-page--content text-center pt-2"><Container maxWidth="lg" className="job-page">{pageErrorMessage}</Container></main>;


  console.log("Job page", job);
  return (
    <main className="bc-page--content">

      <div className="breadcrumbs container">
        <Container maxWidth="lg">
          <Button component={Link} to="/schedule" startIcon={<KeyboardBackspace />}>Return To Schedule</Button>
        </Container>
      </div>
      <Container maxWidth="lg" className="job-page">
        <JobHeaderMemoized job={job} />
        <ButtonGroup variant="outlined" size="small">
    
          <InstallMachine job={job}  />
          <SwapMachine job={job} defaultMachine={defaultMachine}  />
          <RemoveMachine job={job} defaultMachine={defaultMachine} />
        </ButtonGroup>
        <JobDetailsMemoized job={job} />
        {(job?.status?.toLowerCase() !== "completed" && job?.status?.toLowerCase() !=='reschedule') && (
            <Reschedule call={job} />
        )}
        <JobProgressMemoized jobStatus={job?.status?.toLowerCase()} job={job} assessmentsComplete={assessmentsComplete} onUpdateJobStatus={handleUpdateJobStatus} />
        <DisplayCompletedJob  job={job} />
        <JobAccordionMemoized job={job} />
      </Container>
    </main>
  );
}
