import React, { useEffect, useState } from "react";
import moment from "moment";
import ListViewItem from "./ListViewItem";
import { useTheme } from "@mui/material/styles";
import { Button, ButtonGroup, Dialog, useMediaQuery } from "@mui/material";
import MapView from "./MapView";
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
const DayComponent = ({ date, jobs }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [view, setView] = useState("list"); //list or map
  const [mapOpen, setMapOpen] = useState(false);
  // Filter jobs for the given date
  const dailyJobs = jobs.filter((job) =>
    moment(job.date, "YYYY-MM-DD").isSame(date, "day")
  );
  //console.log("jobs",jobs);
  //order by time
  dailyJobs.sort((a, b) => {
    if (a.time < b.time) {
      return -1;
    }
    if (a.time > b.time) {
      return 1;
    }
    return 0;
  });
  //set content of number of jobs on a date the id is jobs-date-20240322
  const buildGoogleMapsLink = (dailyJobs, useCurrentLocation = false) => {
    const baseURL = "https://www.google.com/maps/dir/";
    let url = baseURL;

    if (useCurrentLocation) {
      url += "My+Location"; // Google Maps understands "My Location" as the user's current position
    } else if (dailyJobs.length > 0) {
      // Optionally set the first job as the origin if not using current location
      const firstJob = dailyJobs[0];
      url += `${firstJob.latitude},${firstJob.longitude}`;
      dailyJobs = dailyJobs.slice(1); // Remove the first job as it's already used as the origin
    }

    dailyJobs.forEach((job, index) => {
      // Append the rest of the jobs as waypoints
      url += `/${job.latitude},${job.longitude}`;
    });

    return url;
  };

  
  useEffect(() => {
    try {
      let content = `${dailyJobs.length}`;
      if (!isMobile) content = `Jobs: ${dailyJobs.length}`;
      document.getElementById(
        `jobs-date-${moment(date, "YYYY-MM-DD").format("YYYYMMDD")}`
      ).innerHTML = content;
    } catch (e) {}
  }, [dailyJobs]);

  return (
    <div
      id={moment(date, "YYYY-MM-DD").format("YYYYMMDD")}
      className="day-component"
    >
      <div className="schedule-list--title">
        <h5 className="schedule-day">
          {moment(date, "YYYY-MM-DD").format("ddd, MMM D")} ({dailyJobs.length}{" "}
          Jobs)
        </h5>
        <div style={{ display: "block" }}>
          <ButtonGroup>
            {dailyJobs.length > 1 && (
              <Button
                size="small"
                color="persiangreen"
                variant="text"
                onClick={() => setMapOpen(true)}
              >
                Map run
              </Button>
            )}
            {dailyJobs.length > 0 && (
              <>
                
                <Button
                  size="small"
                  variant="text"
                  color="persiangreen"
                  component="a"
                  href={buildGoogleMapsLink(dailyJobs, true)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Map Run
                </Button>
              </>
            )}
          </ButtonGroup>
        </div>
      </div>
      {dailyJobs.length > 0 ? (
        // If there are jobs, render them here (you might already have a list component for this)
        dailyJobs.map((call, index) => (
          // Render each job as desired, for example:
          <ListViewItem key={index} call={call} />
          //this is where map view will go
        ))
      ) : (
        // If there are no jobs for this day, display a message
        <p className="no-jobs-message">No jobs</p>
      )}
      <Dialog fullScreen open={mapOpen} onClose={() => setMapOpen(false)}>
        <MapView dailyJobs={dailyJobs} />
        <Button
          onClick={() => setMapOpen(false)}
          color="persiangreen"
          variant="contained"
        >
          Close Directions map
        </Button>
      </Dialog>
    </div>
  );
};

export default DayComponent;
