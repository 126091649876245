import { Outlet } from 'react-router-dom';
import { DataProvider } from '../contexts/DataContext';

const DataLayout = () => {

  return (
    <DataProvider>
      <Outlet />
    </DataProvider>
  );
};

export default DataLayout;