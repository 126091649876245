import { Autocomplete, TextField, Grid, Button } from "@mui/material";

//swapping a machine with another machine
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useDataContext } from "../../../contexts/DataContext";
import { req, batchUpdate } from "../../../utils";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";

export function SwapMachine({ job, defaultMachine }) {
  const { machines, updateProperty, venueLocations, appendJobLog, batchUpdate } = useDataContext();
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [venueMachines, setVenueMachines] = useState([]);
  const [warehouseMachines, setWarehouseMachines] = useState([]);
  const [machineOut, setMachineOut] = useState(null);
  const [machineIn, setMachineIn] = useState(null);
  const [warehouseID, setWarehouseID] = useState(null);

  const handleSubmit = async () => {
    if (!machineOut || !machineIn) {
      setSnackbarMessage("Please select both machines to swap.");
      setSnackbarOpen(true);
      return;
    }

    setWarehouseID(machineIn.venue_id);
    const postData = {
      service_call_id: job.service_call_id,
      machine_to_warehouse: machineOut.machine_id_pk,
      machine_to_venue: machineIn.machine_id_pk,
      venue_id: job.service_venue_id,
      venue_location_id: machineOut.venue_location_id,
      time: new Date().toISOString(),
      to_warehouse_id: machineIn.venue_id,
    };

    try {
      setOpen(false);
      setSnackbarMessage("Swapping machines...");
      setSnackbarOpen(true);
      appendJobLog(job.service_call_id, {
        time: Math.floor(Date.now() / 1000),
        event: "machine_swapped",
        details: {
          machine_to_warehouse: machineOut.machine_id_pk,
          machine_to_venue: machineIn.machine_id_pk,
          venue_id: job.service_venue_id,
          venue_location_id: machineOut.venue_location_id,
          to_warehouse_id: machineIn.venue_id,
        },
      });
      // Prepare updates for batch processing
      const updates = [
        //update the machine going to warehouse
        {
          objectType: "machine",
          id: machineOut.machine_id_pk,
          property: "venue_id",
          value: machineIn.venue_id
        },
        {
          objectType: "machine",
          id: machineOut.machine_id_pk,
          property: "venue_id_fk",
          value: machineIn.venue_id
        },
        {
          objectType: "machine",
          id: machineOut.machine_id_pk,
          property: "venue_location_id_fk",
          value: 0
        },
        //update the machine coming into venue
        {
          objectType: "machine",
          id: machineIn.machine_id_pk,
          property: "venue_id",
          value: job.service_venue_id
        },
        {
          objectType: "machine",
          id: machineIn.machine_id_pk,
          property: "venue_id_fk",
          value: machineIn.venue_id
        },
        {
          objectType: "machine",
          id: machineIn.machine_id_pk,
          property: "venue_location_id_fk",
          value: machineOut.venue_location_id_fk//use the venue_location_id of the machineOut
        }
      ];

      // Update job's machine_ids_array
      const updatedMachineIds = [...job.machine_ids_array, machineIn.machine_id_pk];
      updates.push({
        objectType: "job",
        id: job.service_call_id,
        property: "machine_ids_array",
        value: updatedMachineIds
      });

      // Apply all updates in a single batch
      batchUpdate(updates);

      // Log the swap event
      

      await req("post", "swap_machines_v2", postData);

      setSnackbarMessage("Machines swapped successfully!");
    } catch (error) {
      console.error("Error swapping machines:", error);
      setSnackbarMessage("Error swapping machines. Please try again.");
    } finally {
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (!Array.isArray(machines) || !job) return;
    //get all machines at venue as the possible outgoing machine(s)
    const venueMachines = machines.filter(
      (machine) => String(machine.venue_id) === String(job.service_venue_id)
    );

    //console.log("filtered venue machines", venueMachines);
    //get all warehouse  machines as possible incoming machine(s)
    //get unique venue_ids, venue_names from the venueLocations where is_warehouse is true
    //console.log("venueLocations", venueLocations);
    let warehouses = venueLocations.filter(
      (venue) => String(venue.is_warehouse) === "1"
    );

    // Deduplicate warehouses based on venue_id_fk
    warehouses = warehouses.filter(
      (venue, index, self) =>
        index ===
        self.findIndex(
          (t) => String(t.venue_id_fk) === String(venue.venue_id_fk)
        )
    );

    //warehouse machines are all machines where they are in a warehouse, lets join the warehouses to the machines so we can add where its coming from to the select
    let warehouseMachines = machines.filter((machine) =>
      warehouses.some((warehouse) => warehouse.venue_id_fk === machine.venue_id)
    );
    //append the warehouse name to the machine
    warehouseMachines = warehouseMachines.map((machine) => {
      const warehouse = warehouses.find(
        (warehouse) => warehouse.venue_id_fk === machine.venue_id
      );
      return { ...machine, warehouse_name: warehouse.venue_name };
    });
    //iliminate the machines that are already at the venue
    warehouseMachines = warehouseMachines.filter(
      (machine) =>
        !venueMachines.some(
          (venueMachine) => venueMachine.serial_id === machine.serial_id
        )
    );
    //console.log("warehouseMachines", warehouseMachines);
    setWarehouseMachines(warehouseMachines);
    setVenueMachines(venueMachines);
  }, [job, machines, venueLocations]);
  const handleButtonClick = () => {
    if (job.status === "scheduled" || job.status === "NOT STARTED") {
      alert("Please start the job before installing a machine.");
    } else {
      setOpen(true);
    }
  };
  return (
    <>
      <Button color="persiangreen" onClick={() => setOpen(true)}>
        Swap machine
      </Button>
      <Dialog open={open} fullWidth={true} onClose={handleButtonClick}>
        <DialogTitle>SWAP Machine</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="machine-out"
                options={venueMachines}
                getOptionLabel={(option) => `${option.serial_id} (${option.machine_type})`}
                onChange={(event, value) => setMachineOut(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Machine going to warehouse"
                    variant="outlined"
                  />
                )}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="machine-in"
                options={warehouseMachines}
                getOptionLabel={(option) =>
                  `${option.serial_id} (${option.machine_type}) from ${option.warehouse_name}`
                }
                onChange={(event, value) => setMachineIn(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Machine coming into this venue"
                    variant="outlined"
                  />
                )}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="persiangreen"
            style={{ width: "25%" }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={!machineOut || !machineIn}
            style={{ width: "75%" }}
            color="persiangreen"
            onClick={handleSubmit}
          >
            Swap machines
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "var(--bc-persiangreen)",
            color: "#fff",
          },
        }}
      />
    </>
  );
}
