import React, { useState, useEffect } from 'react';
import ProtectedImageGallery from './ProtectedImageGallery';

export function ImageGallery({ images,collectionName }) {
    //console.log('ImageGallery:', images);
    const [isLoading, setIsLoading] = useState(true);
    const [loadedImages, setLoadedImages] = useState([]);

    useEffect(() => {
        //console.log('Effect running with images:', images);
        if (images && images.length > 0) {
            setLoadedImages(images);
            setIsLoading(false);
            //console.log('Images set, loading false');
        } else {
            setIsLoading(true);
            //console.log('No images, loading true');
        }
    }, [images]);

    if (isLoading) {
        return <div>Loading images...</div>;
    }

    return <ProtectedImageGallery images={loadedImages} collectionName={collectionName} />;
}
