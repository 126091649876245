import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { EditMachineLocation } from "./EditMachineLocation";
import { MachineHistory } from "./MachineHistory";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useDataContext } from "../../contexts/DataContext";
export function MachineRow({ machine, hasMoved, movementType, job }) {
  // Added movementType prop
  const { venueLocations } = useDataContext();
  //console.log("MachineRow", venueLocations);
  let location = "New location";
  if (parseInt(machine.location_id) > 0) {
    try {
      location = venueLocations.find(
        (location) =>
          String(location.location_id) === String(machine.location_id)
      ).location;
    } catch (error) {
      location = "New location";
      console.log("Error finding location", error);
    }
  }

  const isRemoved = movementType === "removed";

  const getMovementIcon = () => {
    switch (movementType) {
      case "installed":
        return <AddIcon color="success" />;
      case "removed":
        return <RemoveIcon color="error" />;
      case "swapped":
        return <SwapHorizIcon color="primary" />;
      case "Swapped In":
        return <SwapHorizIcon color="primary" />;
      case "Swapped Out":
        return <SwapHorizIcon color="error" style={{ marginRight: "4px" }} />;
      default:
        return null;
    }
  };
  if (isRemoved || movementType == "Swapped Out") {
    return (
      <Paper
        elevation={1}
        sx={{
          padding: 1,
          marginBottom: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        {getMovementIcon()}
        <Typography>
          Machine <b>{machine.serial_id}</b> is going to the warehouse.
        </Typography>
      </Paper>
    );
  }
  return (
    <Paper
      elevation={1}
      sx={{
        padding: 1,
        marginBottom: 1,
      }}
    >
      <Grid
        container
        className={`machine-details ${
          hasMoved ? "machine-details--moved" : ""
        }`}
      >
        <Grid item xs={12} sm={4}>
          <div>Machine: {machine.serial_id}</div>
          <div>Type: {machine.machine_type}</div>
          {movementType && (
            <Typography variant="body2" display="flex" alignItems="center">
              {getMovementIcon()}
              <span style={{ marginLeft: "4px" }}>{movementType}</span>
            </Typography>
          )}
        </Grid>
        {!isRemoved && ( // Only show EditMachineLocation if not removed
          <Grid item xs={12} sm={4}>
            <EditMachineLocation
              machineID={machine.machine_id_pk}
              venueID={machine.venue_id}
              machineLocationID={machine.location_id}
              machineLocation={location}
              job={job}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={4}>
          <MachineHistory
            machineID={machine.machine_id_pk}
            machineSerialID={machine.serial_id}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
