
import { useRef, useState } from "react";
import { req } from "../../../utils";

import { Button, Dialog, DialogContent, DialogTitle, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { BorderColor, Close, List, Subject, Title } from '@mui/icons-material';

const CustomBackdrop = (props) => {
    return <div {...props} onClick={(e) => e.stopPropagation()} />;
};

export default function FeedBack() {
    return null;
    const [open, setOpen] = useState(false);
    const [feedbackType, setFeedbackType] = useState("Bug");
    const feedbackTitleRef = useRef(null);
    const feedbackDescriptionRef = useRef(null);

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = (event) => {
        // Prevent the click event from reaching the underlying elements
        event.stopPropagation();
        setOpen(false);
    };

    const handleChangeType = (event) => {
        setFeedbackType(event.target.value);
    };

    const handlePostFeedback = async () => {
        const feedbackTitle = feedbackTitleRef.current.value;
        const feedbackDescription = feedbackDescriptionRef.current.value;

        try {
            const response = await req('post', 'create_feedback', {feedbackType, feedbackTitle, feedbackDescription});

            if (response) {
                setOpen(false);
            } else {
                console.warn('Failed to submit feedback');
                // TODO: Handle error scenarios accordingly
            }
        } catch (error) {
            console.error('Error during feedback submission', error);
            // Handle network or other errors
        }
    };

    return (<>
        <div className="btn-feedback--wrapper">
            <button onClick={handleClickOpen} className="btn-feedback">
                <BorderColor sx={{ fontSize: "15px" }} />
                <span className="label">Give feedback</span>
            </button>
        </div>
        <Dialog
            open={open}
            onClose={handleClose}
            backdrop={CustomBackdrop}
            className="dialog-feedback"
        >
            <DialogTitle>
                <div>BottleCycler</div>
                <IconButton alt="close" aria-label="close" onClick={handleClose} size="small">
                    <Close fontSize="small" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div style={{ marginBottom: "18px", width: "100%" }}>
                    <div className="flex flex-row items-start">
                        <div className="basis-1_12 flex flex-col text-center">
                            <List />
                        </div>
                        <div className="basis-11_12">
                            <InputLabel className="form-label">Type</InputLabel>
                            <Select
                                id="feedbackType"
                                value={feedbackType}
                                onChange={handleChangeType}
                                size="small"
                                color="persiangreen"
                                sx={{ backgroundColor: '#fff', width: "100%" }}
                            >
                                <MenuItem value="Bug">Bug</MenuItem>
                                <MenuItem value="New Feature">New Feature</MenuItem>
                                <MenuItem value="Improvement">Improvement</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: "18px", width: "100%" }}>
                    <div className="flex flex-row items-start">
                        <div className="basis-1_12 flex flex-col text-center">
                            <Title />
                        </div>
                        <div className="basis-11_12">
                            <InputLabel className="form-label">Title</InputLabel>
                            <TextField
                                id="feedbackTitle"
                                inputRef={feedbackTitleRef}
                                color="persiangreen"
                                size="small"
                                sx={{ backgroundColor: "#fff", width: "100%" }}
                            ></TextField>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: "18px", width: "100%" }}>
                    <div className="flex flex-row items-start">
                        <div className="basis-1_12 flex flex-col text-center">
                            <Subject />
                        </div>
                        <div className="basis-11_12">
                            <InputLabel className="form-label">Description</InputLabel>
                            <TextField
                                id="feedbackDescription"
                                inputRef={feedbackDescriptionRef}
                                multiline
                                maxRows={9}
                                color="persiangreen"
                                sx={{ backgroundColor: "#fff", width: "100%" }}
                                defaultValue={`What's your issue?\n\nWhat did you expect?\n\n\n---\nNote\nGive as much info as you want to help our devs fix the issue.`}
                            ></TextField>
                        </div>
                    </div>
                </div>
                <Button variant="contained" color="persiangreen" onClick={handlePostFeedback} size="large" sx={{ width: "100%" }}>Send Feedback</Button>
            </DialogContent>
        </Dialog>
    </>);
}