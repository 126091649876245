import React, { useEffect, useState } from "react";
import { Grid, Button, TextField, Autocomplete, Paper } from "@mui/material";
import ImageUploader from "../ImageUploader";
import ProtectedImageGallery from "./ProtectedImageGallery";
import { useDataContext } from "../../contexts/DataContext";
import {log} from '../../utils';
const Images = ({ job }) => {
    const { updateProperty, venueLocations, machines } = useDataContext();
    const [showUpload, setShowUpload] = useState(false);
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState([]);
    const [tagsAvailable, setTagsAvailable] = useState([]);
    const [images, setImages] = useState([]);
    const [uploadMetaData, setUploadMetaData] = useState([]);
    const [initFilledTags, setInitFilledTags] = useState(false);

    useEffect(() => {
        try {
            setUploadMetaData([
                {
                    name: "tags",
                    value: tags.map((tag) => ({
                        foreign_id: tag.foreign_id,
                        foreign_subject: tag.foreign_subject,
                    })),
                },
                { name: "notes", value: description },
            ]);
        } catch (error) {
            log("Error in useEffect:", error);
        }
    }, [description, tags]);

    useEffect(() => {
        let allImages = []; // This will hold images from both sources
    
        // Process images_with_meta
        if (job?.images_with_meta && Array.isArray(job.images_with_meta)) {
            allImages = job.images_with_meta.map((image) => {
                try {
                    return {
                        path: image.image,
                        notes: image.meta_data?.notes,
                        tags: formatTags(image?.related_tags),
                        id: image.image_id,
                    };
                } catch (error) {
                    console.log("Error parsing image metadata:", image, error);
                    log("Error parsing image metadata:", error);
                    return null;
                }
            }).filter(image => image !== null); // Filter out any null entries
        }
    
        // Process call_images
        if (job?.call_images && Array.isArray(job.call_images)) {
            const callImages = job.call_images.map((image) => {
                try {
                    return {
                        path: image,
                        notes: "Added by admin",
                        tags: "",
                        id: 0, // Assuming 0 for default id if not provided
                    };
                } catch (error) {
                    console.log("Error parsing call image:", image, error);
                    log("Error parsing call image:", error);
                    return null;
                }
            }).filter(image => image !== null); // Filter out any null entries
    
            allImages = [...allImages, ...callImages]; // Combine images from both sources
        }
    
        setImages(allImages); // Update state with combined images
    
        // Handle tags available
        let availableTags = [];
        if (job.venue_id) {
            availableTags.push({
                title: `Venue: ${getVenueName(job.venue_id)}`,
                foreign_id: job.venue_id,
                foreign_subject: "venue",
            });
        }
        if (job.machine_ids_array && Array.isArray(job.machine_ids_array)) {
            job.machine_ids_array.forEach((machine_id) => {
                availableTags.push({
                    title: `Machine: ${getMachineSerial(machine_id)}`,
                    foreign_id: machine_id,
                    foreign_subject: "machine",
                });
            });
        }
        setTagsAvailable(availableTags);
    }, [job, venueLocations, machines, tags]);
    
    useEffect(() => {
        try {
            if (tags.length === 0 && !initFilledTags && tagsAvailable.length > 0) {
                setTags(tagsAvailable);
                setInitFilledTags(true);
            }
        } catch (error) {
            log("Error in useEffect:", error);
        }
    }, [tagsAvailable]);

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleTagsChange = (_, newValue) => {
        setTags(newValue);
    };

    const formatTags = (tags) => {
        if (!tags || tags.length === 0) return [];
        return tags.map((tag) => {
            let title = "";
            if (!tag) return;
            if (tag.foreign_subject === "venue") {
                title = `Venue: ${getVenueName(tag.foreign_id)}`;
            }
            if (tag.foreign_subject === "machine") {
                title = `Machine: ${getMachineSerial(tag.foreign_id)}`;
            }
            return {
                foreign_id: tag.foreign_id,
                foreign_subject: tag.foreign_subject,
                title,
            };
        });
    };

    const getVenueName = (venue_id) => {
        try {
            return (
                venueLocations.find(
                    (venue) => String(venue.venue_id_fk) === String(venue_id)
                )?.venue_name || "Venue"
            );
        } catch (error) {
            log("Error in getVenueName:", error);
            return "Venue";
        }
    };

    const getMachineSerial = (machine_id) => {
        try {
            return (
                machines.find(
                    (machine) => String(machine.machine_id_pk) === String(machine_id)
                )?.serial_id || ""
            );
        } catch (error) {
            log("Error in getMachineSerial:", error);
            return "";
        }
    };

    const successCallback = (data) => {
        try {
            let newImages = [];
            for (const image of data) {
                try {
                    newImages.push({
                        id: image.image_id,
                        path: image.path,
                        notes: image.meta_data.notes,
                        tags: formatTags(image.meta_data.tags),
                    });
                } catch (error) {
                    console.log("Error processing image data:", error);
                }
            }
            setImages([...images, ...newImages]);
        } catch (error) {
            log("Error in successCallback:", error);
        }
    };

    const failureCallBack = (data) => {
        console.log("tempFailureCallBack", data);
        //show disappearing snackbar

        //notify user of failure
    };

    const toggleShowUpload = () => {
        setShowUpload(!showUpload);
    };

    try {
        images.sort((a, b) => parseInt(b.id) - parseInt(a.id));
    } catch (error) {
        log("Error sorting images:", error);
    }

    return (
        <div>
            <Paper
                elevation={3}
                style={{ padding: "1rem", marginBottom: "1.5rem" }}
            >
                <Button
                    onClick={() => toggleShowUpload()}
                    variant="outlined"
                    color="persiangreen"
                    fullWidth
                >
                    Add images
                </Button>
                {showUpload && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextField
                            sx={{marginTop: '1rem'}}
                                label="Image description"
                                value={description}
                                onChange={handleDescriptionChange}
                                fullWidth
                                color="persiangreen"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Autocomplete
                                multiple
                                options={tagsAvailable}
                                value={tags}
                                onChange={handleTagsChange}
                                getOptionLabel={(option) => option.title}
                                renderInput={(params) => (
                                    <TextField {...params} label="Tags" color="persiangreen" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ImageUploader
                                endpointUrl={"upload"}
                                primaryUse={"service_calls"}
                                primaryUseKey={job.service_call_id}
                                otherFormData={uploadMetaData}
                                successCallback={successCallback}
                                failureCallback={failureCallBack}
                            />
                        </Grid>
                    </Grid>
                )}
            </Paper>

            <Paper elevation={3} style={{ padding: "1rem", marginBottom: "1.5rem" }}>
                <ProtectedImageGallery images={images} collectionName={"call images"} />
            </Paper>
        </div>
    );
};

export default Images;
