import { useEffect, useRef, useState } from 'react';
import { req, log } from '../../../utils';
import { useDataContext } from '../../../contexts/DataContext';
import { Button, CircularProgress, Snackbar, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { SuggestedMessagesChips } from './SuggestedMessagesChips';

export function MessageForm({ job }) {
    const { appendJobLog } = useDataContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [suggestedMessages, setSuggestedMessages] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const messageRef = useRef(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [feedbackSent, setFeedbackSent] = useState([]);

    const handleSuggestionClick = (suggestion) => {
        if (messageRef.current) {
            const trimmedValue = messageRef.current.value.trim();
            messageRef.current.value = trimmedValue ? trimmedValue + ' ' + suggestion : suggestion;
        }
    };

    const get_feedback_sent = () => {
        try {
            let log = job?.log || [];
            let feedback = log?.filter((item) => item.event === "message_to_account_rep") || [];
            if (feedback.length > 0) {
                feedback = feedback.map((item) => {
                    if (typeof item.details === "string") {
                        try {
                            item.details = JSON.parse(item.details);
                            item.message = item.details.message;
                        } catch (e) {
                            log('Error parsing details', e);
                        }
                        let time = parseInt(item.time) * 1000;
                        item.date = new Date(time).toLocaleString();
                    } else {
                        item.message = item.details.message;
                        item.date = new Date(item.time).toLocaleString();
                    }
                    return item;
                });
                setFeedbackSent(feedback);
            }
        } catch (error) {
            log('Error getting feedback sent', error);
        }
    };
    //console.log('feedbackSent:', feedbackSent);
    const handleSubmit = async () => {
        try {
            const messageValue = messageRef.current.value.trim();
            if (!messageValue) {
                setSuccessMessage('You need a message to send please!');
                setSnackbarOpen(true);
                return;
            }

            let log_time = new Date().getTime();
            let job_id = job?.service_call_id;
            const postData = {
                service_call_id: job_id,
                venue_id: job?.venue_id,
                service_call_type: job?.service_call_type,
                message: messageValue,
                log_time
            };
            req('post', 'notify_account_rep', postData);

            let log = {
                account: localStorage.getItem('_bc_tech_user_id'),
                details: { message: messageValue },
                event: "message_to_account_rep",
                everything: {},
                time: log_time
            };
            appendJobLog(job_id, log);

            setSuccessMessage('Message sent successfully!');
            setSnackbarOpen(true);
            messageRef.current.value = '';
            setTimeout(() => {
                setSuccessMessage('');
                setSnackbarOpen(false);
            }, 3000);
            setIsSubmitting(false);
        } catch (error) {
            log('Error submitting message', error);
        }
    };
    const formatDate = (timestamp)=>{
        let time = parseInt(timestamp);
        return new Date(time).toLocaleString();
    
    }
    useEffect(() => {
        setSuggestedMessages(["Happy customer, get review.", "Angry customer, needs support.", "Could use more machines."]);
        get_feedback_sent();
    }, []);

    useEffect(() => {
        get_feedback_sent();
    }, [job]);

    return (
        <form>
            {suggestedMessages && suggestedMessages.length > 0 && (
                <SuggestedMessagesChips
                    suggestedMessages={suggestedMessages}
                    handleSuggestionClick={handleSuggestionClick}
                />
            )}
            <TextField
                autoFocus
                id="message"
                name="message"
                multiline
                placeholder="Type message here..."
                fullWidth
                color="persiangreen"
                disabled={isSubmitting}
                inputRef={messageRef}
            />
            <div className="flex flex-row items-center justify-content-between" style={{ marginTop: "1rem" }}>
                <div style={{ fontWeight: '700', marginLeft: '1rem' }}>{successMessage}</div>
                <Button
                    variant="contained"
                    className={isSubmitting ? 'btn-submit btn-persiangreen-hover' : 'btn-submit'}
                    color="persiangreen"
                    disabled={isSubmitting}
                    disableElevation={true}
                    required={true}
                    onClick={handleSubmit}
                    style={{ lineHeight: '1.25rem', minWidth: "200px" }}
                    endIcon={isSubmitting ? <CircularProgress size={16} style={{ marginLeft: '.5rem', marginTop: "-4px" }} /> : <SendIcon size={16} style={{ marginLeft: '.5rem', marginTop: "-4px" }} />}
                >
                    {isSubmitting ? 'Sending' : 'Send Message'}
                </Button>
            </div>
            <div style={{ textAlign: 'left', display: 'block', marginTop: "1rem" }}>
                {feedbackSent.length === 0 ? null : (
                    <div className='feedback-sent-title'>Feedback already sent</div>
                )}
                {feedbackSent.map((item, index) => {
                    return (
                        <div className={"feedback"} key={index}>
                            <div className='message'>{item.message}</div>
                            <div className='date'>{formatDate(item.time)}</div>
                        </div>
                    );
                })}
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={successMessage || errorMessage}
            />
        </form>
    );
}
